
import React from "react";
import Select, {createFilter} from "react-select";
import {BsCheck} from "react-icons/bs";
import Overlay from "./Overlay";
import AdminAPI from "../network/AdminAPI";
import moment from "moment";
import { getUnwrappedSelect } from "../util/FormatUtil";
import FixedSizeList from "./PodMenuList"
import {sweetalert} from "../App"
import PodSched, { PodSchedForSelect } from "../types/PodSched"

interface PodProps{
    onChange:any,
    multi?,
    active?,
    vaccine?,
    testing? ,
    defaultPod?
    shouldReset?
    isActive?
    passClearStateFunc?
}
interface PodState{
    pods?:any[]
    selectedPod?:any
    changedPod?
    show_loading
}
class PodSelect extends React.Component<PodProps, PodState> {
    constructor(props) {
        super(props);
        this.state = {show_loading: false}
    }

    shouldComponentUpdate(nextProps: Readonly<PodProps>, nextState: Readonly<PodState>, nextContext: any): boolean {
        if(this.props.passClearStateFunc) {
            this.props.passClearStateFunc(this.clearState);
        }
        return true;
    }

    clearState = () =>{
        this.setState({selectedPod: null})
    }


    componentDidMount(): void {
        this.setState({show_loading: true}, () => {
            
            AdminAPI.getAllPods(true, this.props.active).then(response => {
                if(!response.success){
                    sweetalert.fire("Error loading Pods for PodSelect");
                }

                let pods = response.data as {label: string; value: PodSchedForSelect}[];

                const today = moment().startOf('day');
                const beforeToday = pods.filter((pods)=>{
                        let aDate = moment(pods.value.TestDate, 'YYYY-MM-DD').startOf('day');
                        return aDate.isBefore(today)
                }).reverse();
                const afterToday = pods.filter((pods)=>{
                    let bDate = moment(pods.value.TestDate, 'YYYY-MM-DD').startOf('day');
                    return bDate.isSameOrAfter(today)
                })
                let groupedPods = [
                    { label: "Upcoming", options: afterToday},
                    { label: "Past", options: beforeToday}
                ]
                this.setState({pods:groupedPods , show_loading: false});
            })
        })
    }


    componentWillReceiveProps(nextProps: Readonly<PodProps>, nextContext: any) {
        if(nextProps.defaultPod && !this.state.changedPod)
            this.setState({selectedPod:nextProps.defaultPod});
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let pods = JSON.parse(JSON.stringify(this.state.pods ? this.state.pods : []));
        return (<React.Fragment>
                <Overlay show_loading={this.state.show_loading}/>
                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Lists the Schedule ID, Facility Name, and Schedule Date'}>
                    <label id={'Schedule'} className="col-sm-4 col-form-label">Schedule
                    {this.props.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
                    </label>
                    <div className="col-sm-8  p-0 m-0">
                        <Select
                            for={'Schedule'}
                            isSearchable={ true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={()=>  "No option"}
                            isMulti={this.props.multi}
                            getOptionValue={option => option.value.ID}
                            value={this.state.selectedPod}
                            onChange={(e)=> this.setState({selectedPod: e, changedPod:true}, () => this.props.onChange({pod:getUnwrappedSelect(e)}))}
                            className={"state_select"}
                            options={pods}
                            filterOption={createFilter({ignoreAccents:false})}
                            //@ts-ignore
                            components={{FixedSizeList}}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default PodSelect