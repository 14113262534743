import React from "react";
import ModalForm from "./ModalForm";
import {hideModal} from "../../util/FormatUtil";
import {sweetalert} from "../../App";


interface InsuranceCardUploadProps{
    frontUpload: (img: File) => void;
    backUpload: (img: File) => void;
    clearFiles: () => void;
    handleLoading?;
    handleImageSavedButton?: (bool:boolean) => void;
    isDupe
    closeInBetween?: () => void
    clearImageState?: () => void
}

interface InsuranceCardUploadState{
    frontFileForDupeCheck?:File
    backFileForDupeCheck?:File
    frontImageBool:boolean
    backImageBool:boolean
}

export class InsuranceCardUploadModal extends React.Component<InsuranceCardUploadProps, InsuranceCardUploadState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state = {
            frontImageBool: false,
            backImageBool: false,
        };
        this.formRef = React.createRef()
    }

    componentDidMount(): void {
        $(`#${InsuranceCardUploadModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    clear = () => {
        const frontUpload = document.querySelector('#FrontCardCapture') as any;
        frontUpload.value = '';
        const backUpload = document.querySelector('#BackCardCapture') as any;
        backUpload.value = '';
        hideModal(InsuranceCardUploadModal.ID)
        this.props.handleImageSavedButton(false)
        this.props.handleLoading();
        this.setState({frontImageBool: false, backImageBool: false})
        this.props.clearFiles()
    }


    dupeCheck(){
        if(this.state.frontFileForDupeCheck.name === this.state.backFileForDupeCheck.name){
            this.props.handleImageSavedButton(false)
            return sweetalert.fire({icon: 'error', title: '', text: 'Duplicate images detected'});
        }
        else{
            this.props.closeInBetween()
                hideModal(InsuranceCardUploadModal.ID);
                this.props.handleLoading();
                this.props.handleImageSavedButton(true)
            // sweetalert.fire({
            //     icon: 'info',
            //     title: '',
            //     text: "Insurance Card Images will not be uploaded until record is submitted/saved."
            // }).then(result => {
            //     this.props.closeInBetween()
            //     hideModal(InsuranceCardUploadModal.ID);
            //     this.props.handleLoading();
            //     this.props.handleImageSavedButton(true)
            // });
        }
    }

    handleCloseModalInsuranceUpload(){
        hideModal(InsuranceCardUploadModal.ID)
        this.props.closeInBetween()
        this.props.clearImageState()
        this.clear()
    }

    public static readonly ID = "InsuranceCardUpload"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={InsuranceCardUploadModal.ID} tabIndex={-1} role="dialog" style={{zIndex: 100006}} data-backdrop="static" data-keyboard="false"
                     aria-label="Insurance Card Upload"
                     aria-hidden="true">
                    <div className="modal-dialog modal-xl " role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid  min-vh-10">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2 text-center">
                                                <div className="card-header verlag-bold">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <h4>
                                                                Insurance Card Upload
                                                            </h4>
                                                        </div>
                                                        <div className="col-2">
                                                            <button style={{outline: 'none'}}  type="button" className="close pr-4"
                                                                    aria-label="Close" onClick={() => this.handleCloseModalInsuranceUpload()}>
                                                                <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className="form-group row">
                                                        <label htmlFor={"FrontCardCapture"} className={"col-sm-6 col-form-label"}>Front of Insurance Card</label>
                                                        <div className={"col-sm-6 p-0 m-0 text-center text-lg-left "}>
                                                            <input type="file" id={"FrontCardCapture"} onChange={(e) => {
                                                                this.setState({frontImageBool: true, frontFileForDupeCheck: e.target.files[0]})
                                                                this.props.frontUpload(e.target.files[0])
                                                            }}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor={"BackCardCapture"} className={"col-sm-6 col-form-label"}>Back of Insurance Card</label>
                                                        <div className={"col-sm-6 p-0 m-0  text-center text-lg-left "}>
                                                            <input type="file" id={"BackCardCapture"} onChange={(e) => {
                                                                this.setState({backImageBool: true, backFileForDupeCheck: e.target.files[0]})
                                                                this.props.backUpload(e.target.files[0])
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!this.state.frontImageBool || !this.state.backImageBool || this.props.isDupe ?
                                                <div className="invisible"></div>
                                                :
                                                <div className="card-footer">
                                                    <button className={"btn btn-primary float-right"} onClick={() => {
                                                        this.dupeCheck();
                                                    }}>
                                                        Save
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

