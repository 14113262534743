import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import {sweetalert} from "../../App";
import PodSelect from "../PodSelect";
import NetworkUtil from "../../network/NetworkUtil";
import AuditLogsAPI from "../../network/AuditLogsAPI";
import {AlertAudit} from "../../types/AlertAudit";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import { capitalize, formatUSDate, getBoolSelectVal, getBoolSelectYN, getFieldTypeVal, getNameFromID, getTwelveHourAMPMTime, parseDate } from "../../util/FormatUtil";
import Parse from "html-react-parser";
import PaginationTool from "../PaginationTool";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import { Column } from "../tables/TableBase";

interface AuditLogState{
    show_loading: boolean
    filter: object
    AuditNew: AlertAudit[]
    pod?: any
    searched: any
    direction?:TableOrder
}

interface AuditLogProps{
    auditLogType?
    batches?
    batchesForSamples?
    tests?
    templates?
    facilities?
    services?
    customFields?
    customQuestions?
    customPatientFields?
    results?
    patients?
    races?
    genders?
    ethnicities?
    files?
    banners?
    branding?
    pricing?
    discount?
}

export class AuditLog extends React.Component<AuditLogProps,AuditLogState>{
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {
            show_loading: false,
            direction: 'asc',
            filter: {},
            AuditNew: [],
            pod: [],
            searched: []
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
        this.handleExportToCSV = this.handleExportToCSV.bind(this);
    }

    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }
    

    async onSubmit() {

        let selection = this.props.auditLogType;
        
        this.setState({show_loading: true})

        // validations
        if(
            (selection === 'patient') && !this.state.filter['UID'] ||
            ((selection === 'sample' || selection === 'insurance') && !this.state.filter['ReqNum']) || 
            (selection === 'facility' && !this.state.filter['FacilityID']) || 
            (selection === 'batch' && !this.state.filter['BatchNum']) || 
            ((selection === 'service' || selection === 'commTemplate' || 
              selection === 'test' || selection === 'pricing' || selection === 'discount') && !this.state.filter['Name']) || 
            ((selection === 'alert' || selection === 'schedule') && (!this.state.pod || this.state.pod.length< 1)) ||
            ((selection === 'customField' && (!this.state.filter['DisplayName']))) ||
            ((selection === 'customQuestion' && (!this.state.filter['DisplayName']))) ||
            ((selection === 'customPatientField' && (!this.state.filter['DisplayName']))) ||
            ((selection === 'labInformation' && (!this.state.filter['IsDefault'] && !this.state.filter['Facility']))) ||
            (selection === 'result' && !this.state.filter['Value']) ||
            (selection === 'file' && !this.state.filter['DocumentName']) ||
            (selection === 'banner' && !this.state.filter['BannerID']) 
        ){
            this.setState({show_loading: false})
            return sweetalert.fire({icon: 'error', title: '', text: "Please complete form"});
        }

        // data fetching
        try {
            let results = 
                selection === 'patient' ? await AuditLogsAPI.patientsAuditLogs({filter: this.state.filter['UID'].trim()}) :
                selection === 'sample' ? await AuditLogsAPI.sampleAuditLogs({filter:this.state.filter["ReqNum"].trim()}) : 
                selection === 'facility' ? await AuditLogsAPI.facilityAuditLogs({filter:this.state.filter["FacilityID"]}) : 
                selection === 'schedule' ? await AuditLogsAPI.podAuditLogs({filter:this.state.pod}) :
                selection === 'batch' ? await AuditLogsAPI.batchAuditLogs({filter:this.state.filter['BatchNum']}) : 
                selection === 'alert' ? await AuditLogsAPI.alertAuditLogs({filter:this.state.pod}) :
                selection === 'service' ? await AuditLogsAPI.serviceAuditLogs({filter:this.state.filter["Name"]}) :
                selection === 'commTemplate' ? await AuditLogsAPI.commTemplateAuditLogs({filter:this.state.filter["Name"]}) : 
                selection === 'customField' ? await AuditLogsAPI.customFieldAuditLogs({filter:this.state.filter["DisplayName"]}) : 
                selection === 'customQuestion' ? await AuditLogsAPI.customFieldAuditLogs({filter:this.state.filter["DisplayName"]}) : 
                selection === 'customPatientField' ? await AuditLogsAPI.customFieldAuditLogs({filter:this.state.filter["DisplayName"]}) : 
                selection === 'labInformation' ? await AuditLogsAPI.labInfoAuditLogs({filter:this.state.filter}) :
                selection === 'insurance' ? await AuditLogsAPI.insuranceAuditLogs({filter:this.state.filter['ReqNum'].trim()}) :
                selection === 'test' ? await AuditLogsAPI.testsAuditLogs({filter:this.state.filter["Name"]}) :
                selection === 'result' ? await AuditLogsAPI.resultAuditLogs({filter: this.state.filter['Value']}):
                selection === 'pswdReq' ? await AuditLogsAPI.pswdConfigAuditLogs({filter: null}) : 
                selection === 'file' ? await AuditLogsAPI.fileMgmtAuditLogs({filter: this.state.filter['DocumentName']}) : 
                selection === 'banner' ? await AuditLogsAPI.bannerMgmtAuditLogs({filter: this.state.filter['BannerID']}) :
                selection === 'pricing' ? await AuditLogsAPI.pricingAuditLogs({filter:this.state.filter["Name"]}) :
                selection === 'discount' ? await AuditLogsAPI.discountAuditLogs({filter:this.state.filter["Name"]}) :
                null;

            if(!results || !results.data || results.data?.length === 0){
                this.setState({show_loading: false, AuditNew: []})
                return sweetalert.fire({icon: 'info', title: '', text: 'No results returned'});
            }

            if(results.data.length > 0){
                this.setState({
                    show_loading: false, 
                    AuditNew: results.data as any, 
                    searched: 
                        (selection === 'patient') ? this.state.filter['UID'] : 
                        (selection === 'sample' || selection === 'insurance' ) ?  this.state.filter["ReqNum"] : 
                        selection === 'facility' ? this.state.filter['FacilityID'] : 
                        (selection === 'schedule' || selection === 'alert') ? this.state.pod :
                        selection === 'batch' ? this.state.filter['BatchNum'] :
                        (selection === 'service' || selection === 'commTemplate' || selection === 'test') ? this.state.filter["Name"] : 
                        selection === 'customField' ? this.state.filter["DisplayName"] : 
                        selection === 'customQuestion' ? this.state.filter["DisplayName"] : 
                        selection === 'customPatientField' ? this.state.filter["DisplayName"] : 
                        selection === 'labInformation' ? this.state.filter :
                        selection === 'result' ? this.state.filter["Value"] : 
                        selection === 'file' ? this.state.filter['DocumentName'] : 
                        selection === 'banner' ? this.state.filter['BannerID'] : 
                        selection === 'labInfo' ? this.state.filter["Name"] :
                        selection === 'pricing' ? this.state.filter["Name"] : 
                        selection === 'discount' ? this.state.filter["Name"] : 
                        null
                })
            }
        }
        catch (e) {
            this.setState({show_loading: false})
            sweetalert.fire({icon: 'error', title: '', text: e});
        }

        
    }

    handleExportToCSV() {
        console.log('auditLogType in handleExportToCSV', this.props.auditLogType)
        let page = this.props.auditLogType === "schedule" ? "pod" : this.props.auditLogType === 'customQuestion' ? 'customField' : this.props.auditLogType === 'customPatientField' ? 'customField': this.props.auditLogType;
        this.setState({show_loading:true}, async () =>{
            await NetworkUtil.downloadCSV(`/api/admin/audit/${page}AuditLogs/csv`,
                `${capitalize(this.props.auditLogType)} Audit Logs.xlsx`,
                {filter: this.state.searched});
            this.setState({show_loading:false});
        });
    }

    getRenderJSX(){
        let jsx;
        switch (this.props.auditLogType) {
            case 'sample':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Confirmation Code",
                                "key": "ReqNum",
                                type: FIELD_TYPE.TEXT,
                                placeholder: "Enter Confirmation Code",
                            }
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'facility':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Facility",
                                "key": "FacilityID",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                isMapped: true,
                                options: this.props.facilities,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'batch':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Batch #",
                                "key": "BatchNum",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.batches,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'service':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Service Name",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.services,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'result':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Value",
                                "key": "Value",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                isMapped: true,
                                options: this.props.results,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'commTemplate':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Template Name",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.templates,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'customField':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Display Name",
                                "key": "DisplayName",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.customFields,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break; 
            case 'customQuestion':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Display Name",
                                "key": "DisplayName",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.customQuestions,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'customPatientField':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Display Name",
                                "key": "DisplayName",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.customPatientFields,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;          
            case 'insurance':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Confirmation Code",
                                "key": "ReqNum",
                                type: FIELD_TYPE.TEXT,
                                placeholder: "Enter Confirmation Code",
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'schedule':
                jsx = 
                    <PodSelect 
                        passClearStateFunc={this.assignClearState} 
                        onChange={(e) => this.setState({pod: e.pod})}
                    />
                break;
            case 'alert':
                jsx = 
                    <PodSelect 
                        passClearStateFunc={this.assignClearState} 
                        onChange={(e) => this.setState({pod: e.pod})}
                    />
                break;
            case 'labInformation':
                    jsx = <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                        {
                            label: "Is Default",
                            "key": "IsDefault",
                            type: FIELD_TYPE.SELECT,
                            isMulti: false,
                            options: [ 
                                'Yes'
                            ],
                            disable: this.state.filter['Facility'] !== ""
                        },
                        {
                            label: "Facility",
                            "key": "Facility",
                            type: FIELD_TYPE.SELECT,
                            isMulti: false,
                            options: this.props.facilities.map(f => f.label),
                            disable: this.state.filter["IsDefault"] !== ""
                        },
                    ]} filterChanged={(filter) => this.setState({ filter: filter })}
                />
                break;
            case 'test':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Test",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                isMapped: true,
                                options: this.props.tests,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'patient':
                    jsx = 
                        <FilterCard
                            passClearStateFunc={this.assignClearState}
                            fields={[
                                {
                                    label: "UID",
                                    "key": "UID",
                                    type: FIELD_TYPE.TEXT,
                                    placeholder: "Enter Patient UID",
                                }
                            ]} filterChanged={(filter) => this.setState({filter: filter})}
                        />
                    break;
            case 'pricing':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Name",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                isMapped: true,
                                options: this.props.pricing,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
            case 'discount':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Name",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                isMapped: true,
                                options: this.props.discount,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
                case 'file':
                    jsx = 
                        <FilterCard
                            passClearStateFunc={this.assignClearState}
                            fields={[
                                {
                                    label: "File Name",
                                    "key": "DocumentName",
                                    type: FIELD_TYPE.SELECT,
                                    isMulti: false,
                                    isMapped: true,
                                    options: this.props.files,
                                },
                            ]} 
                            filterChanged={(filter) => this.setState({filter: filter})}
                        />
                    break;
                case 'banner':
                    jsx = 
                        <FilterCard
                            passClearStateFunc={this.assignClearState}
                            fields={[
                                {
                                    label: "Product Name",
                                    "key": "BannerID",
                                    type: FIELD_TYPE.SELECT,
                                    isMulti: false,
                                    isMapped: true,
                                    options: this.props.banners,
                                },
                            ]} 
                            filterChanged={(filter) => this.setState({filter: filter})}
                        />
                    break;
            default:
                jsx = <div className="invisible"></div>
                break;
        }
        return jsx;
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()
      
        this.setState({AuditNew: sorter.sortByKey(this.state.AuditNew, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
      }

    getTable(){
        let jsxForTable;
        switch (this.props.auditLogType) {
            case 'sample':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label:"First Name", key:"PatientFirstName"},
                            {label:"Last Name", key:"PatientLastName"},
                            {label:"DOB", key:"PatientDOB", formatFunc:parseDate},
                            {label:"Confirmation#", key:"ReqNum"},
                            {label:"Email", key:"PatientEmail"},
                            {label:"Phone", key:"PatientPhone"},
                            {label:"Results Accessed", key:"ResultsAccessedDate", formatFunc:parseDate},
                            {label:"Specimen ID", key:"SpecimenID"},
                            {label:"Internal Specimen ID", key:"InternalSpecimenID"},
                            {label:"Batch", key:"BatchID", rawFormat: (val) => {
                                let batchNum;
                                for (let i = 0; i < this.props.batchesForSamples.length; i++) {
                                    const element = this.props.batchesForSamples[i];
                                    if(element.value.ID === val.BatchID){
                                        batchNum = element.value.BatchNum
                                        break
                                    }
                                } 
                                return <p>{batchNum}</p>
                            }}
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'facility':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "FacilityName", },
                            {label: "Facility Group", key: "FacilityGroup"},
                            {label: "Facility Seq", key: "FacilitySeq"},
                            {label: "Abbreviation", key: "FacilityAbbreviation"},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'schedule':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label:"Facility", key:"FacilityID", rawFormat: (val) => {
                                let facilityName = getNameFromID(val.FacilityID, this.props.facilities);
                                return <p>{facilityName}</p>
                            }},
                            {label:"Access Code", key:"AccessCode"},
                            {label:"Test Date", key:"TestDate", formatFunc: formatUSDate},
                            {label:"Start Time", key:"StartTime", formatFunc: getTwelveHourAMPMTime},
                            {label:"End Time", key:"EndTime", formatFunc: getTwelveHourAMPMTime},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'batch':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Batch", key: "BatchNum", },
                            {label: "Service", key: "ServiceLabel.Name"},
                            {label: "Approved By", key: "ResultApprovedBy"},
                            {label: "Approved Date", key: "ResultApprovedDate", formatFunc:parseDate}
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'alert':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Schedule ID", key: "PodID", },
                            {label: "Content", key: "Content"},
                            {label: "Receiving Email", key: "TestEmail"},
                            {label: "Receiving Phone", key: "TestPhone"},
                            {label: "Sent by", key: "SentBy"},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'service':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "Name", },
                            {label: "Is Enabled", key: "IsEnabled", rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.IsEnabled)
                                    return <p>{string.label}</p>
                            }},
                            {label: "Is Lab Service", key: "IsLabService", rawFormat: (val) => {
                                let string = getBoolSelectVal(val.IsLabService)
                                return <p>{string.label}</p>
                            }},
                            {label: "HtmlTemplate", key: "HtmlTemplate", rawFormat: (val)=>{
                                let parsed = Parse(val.HtmlTemplate);
                                return <p>{parsed}</p>
                                }
                            },
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'result':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead"
                    columns={[
                      { label: "Result", key: "Value", },
                      { label: "Description", key: "Description", },
                      { label: "Highlight?", key: "EnableHighlighting", rawFormat: (val) => {
                          let string = getBoolSelectVal(val.EnableHighlighting);
                          return <p>{string.label}</p>;
                      }},
                      { label: "Highlight Color", key: "HighlightColorLabel", },
                      { label: "Is Enabled?", key: "IsEnabled", rawFormat: (val) => {
                          let string = getBoolSelectVal(val.IsEnabled);
                          return <p>{string.label}</p>;
                      }},
                    ]}
                    table_data={this.state.AuditNew}
                    columnClickedCallback={(col =>{
                        this.useSorter(col);
                    })}
                  />
                    break;
            case 'commTemplate':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead"
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "Name"},
                            {label: "Subject", key: "Subject"},
                            {label: "Last Updated By", key: "LastUpdatedBy"},
                            {label: "Last Updated", key: "LastUpdatedDate"},
                            {label: "Editable Template", key: "EditableTemplate",
                                rawFormat: (val)=>{
                                let template = val.EditableTemplate ? val.EditableTemplate : val.Template;
                                    let parsed = Parse(template);
                                    return <p>{parsed}</p>
                                }
                            },
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'customField':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead"
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Display Name", key: "DisplayName"},
                            {label: "Field Type", key: "FieldTypeID", rawFormat: (val) => {
                                let string = getFieldTypeVal(val.FieldTypeID)
                                return <p>{string.label}</p>
                            }},
                            {label: "Valid Pages", key: "ValidPages", rawFormat: (val) => {
                                let parsedPages = val.Options.validPages && val.Options.validPages.length > 0 && typeof val.Options.validPages === "string" ? JSON.parse(val.Options.validPages) : val.Options.validPages;
                                let vp = parsedPages ? parsedPages.map(p => p + ", ") : null;
                                let lastInd = vp ? vp.length - 1 : -1;
                                if (lastInd >= 0)
                                    vp[lastInd] = vp[lastInd].slice(0, vp[lastInd].length - 2); // Remove last space
                                return <p>{vp}</p>
                            }},
                            {label: "Is Active", key: "IsActive", rawFormat: (val) => {
                                let string = getBoolSelectVal(val.Options.isActive)
                                return <p>{string.label}</p>
                            }},
                            {label: "Is Required", key: "IsRequired",rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.Options.isRequired)
                                    return <p>{string.label}</p>
                            }},
                            {label: "Is PHI", key: "IsPHI", rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.Options.isPHI)
                                    return <p>{string.label}</p>
                            }},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'customQuestion':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead"
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Display Name", key: "DisplayName"},
                            {label: "Field Type", key: "FieldTypeID", rawFormat: (val) => {
                                let string = getFieldTypeVal(val.FieldTypeID)
                                return <p>{string.label}</p>
                            }},
                            {label: "Valid Pages", key: "ValidPages", rawFormat: (val) => {
                                let parsedPages = val.Options.validPages && val.Options.validPages.length > 0 && typeof val.Options.validPages === "string" ? JSON.parse(val.Options.validPages) : val.Options.validPages;
                                let vp = parsedPages ? parsedPages.map(p => p + ", ") : null;
                                let lastInd = vp ? vp.length - 1 : -1;
                                if (lastInd >= 0)
                                    vp[lastInd] = vp[lastInd].slice(0, vp[lastInd].length - 2); // Remove last space
                                return <p>{vp}</p>
                            }},
                            {label: "Is Active", key: "IsActive", rawFormat: (val) => {
                                let string = getBoolSelectVal(val.Options.isActive)
                                return <p>{string.label}</p>
                            }},
                            {label: "Is Required", key: "IsRequired",rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.Options.isRequired)
                                    return <p>{string.label}</p>
                            }},
                            {label: "Is PHI", key: "IsPHI", rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.Options.isPHI)
                                    return <p>{string.label}</p>
                            }},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'customPatientField':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead"
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Display Name", key: "DisplayName"},
                            {label: "Field Type", key: "FieldTypeID", rawFormat: (val) => {
                                let string = getFieldTypeVal(val.FieldTypeID)
                                return <p>{string.label}</p>
                            }},
                            {label: "Valid Pages", key: "ValidPages", rawFormat: (val) => {
                                let parsedPages = val.Options.validPages && val.Options.validPages.length > 0 && typeof val.Options.validPages === "string" ? JSON.parse(val.Options.validPages) : val.Options.validPages;
                                let vp = parsedPages ? parsedPages.map(p => p + ", ") : null;
                                let lastInd = vp ? vp.length - 1 : -1;
                                if (lastInd >= 0)
                                    vp[lastInd] = vp[lastInd].slice(0, vp[lastInd].length - 2); // Remove last space
                                return <p>{vp}</p>
                            }},
                            {label: "Is Active", key: "IsActive", rawFormat: (val) => {
                                let string = getBoolSelectVal(val.Options.isActive)
                                return <p>{string.label}</p>
                            }},
                            {label: "Is Required", key: "IsRequired",rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.Options.isRequired)
                                    return <p>{string.label}</p>
                            }},
                            {label: "Is PHI", key: "IsPHI", rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.Options.isPHI)
                                    return <p>{string.label}</p>
                            }},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'labInformation': 
                jsxForTable = <SimpleTable table_style='tableFixHead '
                    ref={el => (this.componentRef = el)} columns={[
                        { label: "Entity Name", key: "EntityName" },
                        { label: "Is Default?", key: "IsDefault", rawFormat: (val) => { 
                            let string = getBoolSelectVal(val.IsDefault) 
                            return <p>{string.label}</p> 
                        }},
                        { label: "Director", key: "Director" },
                        { label: "Facility Name", key: "FacilityID", rawFormat: (val) => {
                            return <p>{val.EntityName} </p> 
                        }},
                        {label: "CLIA", key: "CLIA" },
                        { label: "Lab ID", key: "LabID", },
                        { label: "Help Email", key: "HelpEmail" },
                        { label: "Last Modified", key: "LastUpdatedDate",formatFunc:parseDate }
                    ]} table_data={this.state.AuditNew}
                    columnClickedCallback={(col =>{
                        this.useSorter(col);
                    })}
                />
                break;
            case 'insurance':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead "
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Sample ID", key: "SampleID"},
                            {label:"First Name", key:"FirstName"},
                            {label:"Last Name", key:"LastName"},
                            {label:"DOB", key:"DOB", formatFunc:parseDate},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'test':
                jsxForTable = 
                    <SimpleTable table_style="tableFixHead "
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "Name"},
                            {label: "Short Name", key: "ShortName"},
                            {label: "Report Description", key: "ReportDescription", rawFormat: (val)=>{
                                    let parsed = Parse(val.ReportDescription);
                                    return <p>{parsed}</p>
                                }},
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'patient':
                    jsxForTable = 
                        <SimpleTable table_style='tableFixHead'
                            ref={el => (this.componentRef = el)} 
                            columns={[
                                {label:"Name", key:"displayName"},
                                {label:"Email", key:"email"},
                                {label:"Phone #", key:"phoneNumber"},
                                {label:"DOB", key:"dateOfBirth", formatFunc: parseDate},
                                {label:"City", key:"city"},
                                {label:"County", key:"country"},
                                {label:"State", key:"state"},
                                {label:"Country", key:"country"},
                                {label:"Gender", key:"genderID",  rawFormat: (val)=>{
                                    let gender = getNameFromID(val.genderID, this.props.genders);
                                    return <p>{gender}</p>
                                }},
                                {label:"Ethnicity", key:"ethnicityID", rawFormat: (val)=>{
                                    let ethnicity = getNameFromID(val.ethnicityID, this.props.ethnicities);
                                    return <p>{ethnicity}</p>
                                }},
                                {label:"Race", key:"raceID", rawFormat: (val)=>{
                                    let race = getNameFromID(val.raceID, this.props.races);
                                    return <p>{race}</p>
                                }},
                            ]} 
                            table_data={this.state.AuditNew}
                            columnClickedCallback={(col =>{
                                this.useSorter(col);
                            })}
                        />
                break;
            case 'pswdReq' : jsxForTable = 
                    <SimpleTable table_style="tableFixHead"
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Feature", key: "StringID"},
                            {label: "Required Number Of", key: "FeatureText"},
                            {label: "Feature Description", key: "FeatureDescription"},
                            {label: "Last Updated By", key: "LastUpdatedBy"},
                            {label: "Last Updated", key: "LastUpdatedDate", formatFunc:parseDate }
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'pricing':
                jsxForTable = <SimpleTable 
                        table_style="tableFixHead"
                        ref={el => (this.componentRef = el)}
                        columns={[
                            {label:"Name", key:"Name"},
                            {label:"Description", key:"Description"},
                            {label:"Cost", key:"Cost"},
                            {label:"Last Updated", key:"UpdatedDate", formatFunc:parseDate},
                        ]}
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                />
                break;
            case 'discount':
                jsxForTable = <SimpleTable 
                        table_style="tableFixHead"
                        ref={el => (this.componentRef = el)}
                        columns={[
                            {label:"Name", key:"Name"},
                            {label:"Discount Code", key:"DiscountCode"},
                            {label:"Discount Amount", key:"DiscountAmount"},
                            {label:"Last Updated", key:"UpdatedDate", formatFunc:parseDate},
                        ]}
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                />
                break;
            case 'file':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead '
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "File Name", key: "DocumentName", },
                            {label: "URL", key: "URL"},
                            {label: 'View', key: '',
                            //may show product
                            rawFormat: (val) => {
                                return (
                                  <a
                                    href={val.URL}
                                    target="_blank"
                                  >
                                    Click Here
                                  </a>
                                );
                            }}
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            case 'banner':
                jsxForTable = 
                    <SimpleTable table_style='tableFixHead'
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Product", key: "", rawFormat: (val) => {
                                let brand = this.props.branding.find(f => f.BrandingID === val.BrandingID);
                                return (
                                    <div>{brand.BrandingName}</div>
                                );
                            },},
                            {label: "Text", key: "Text"},
                            {label: "Active", key: "Display", rawFormat: (val) => {
                                return (
                                    <div>{getBoolSelectYN(val.Display)}</div>
                                );
                              },},
                            
                        ]} 
                        table_data={this.state.AuditNew}
                        columnClickedCallback={(col =>{
                            this.useSorter(col);
                        })}
                    />
                break;
            default:
                jsxForTable = <div className={'invisible'}></div>
                break;
        }
        return jsxForTable;
    }

    render() {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.show_loading}/>
                <PaginationTool />
                {this.props.auditLogType &&
                <div className={"row"}>
                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="card border-top-0">
                            <div className="card-body">
                                {this.getRenderJSX()}                            
                            </div>
                            <div className="card-footer" style={{height: '4em'}}>
                                <button className={"btn immySubmitButtonOutline verlag-bold"} onClick={this.onSubmit}>Search</button>
                                <button className="btn immyClearButtonOutline float-right" onClick={() => {this.clearFilterState(); this.setState({pod:null, AuditNew: [], searched: []})}}>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {this.state.AuditNew.length > 0 &&
                <div className="col-12 col-md-12 col-lg-12 pt-2 pl-0 pr-0">
                    <div className="card mt-2" style={{marginBottom: '4rem'}}>
                        <div className="card-header verlag-bold stickToTop">
                            <h4>Logs
                                <button className={this.state.AuditNew.length < 1 ? 'd-none': "btn btn-outline-primary d-none d-md-inline-block ml-2 float-right"} onClick={() => this.handleExportToCSV()}>Export to CSV</button>
                            </h4>
                        </div>
                        <div className="card-body p-0 m-0 table-responsive">
                            {this.getTable()}
                        </div>
                        
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}