import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getBoolSelectOptions,
    getBoolSelectVal, getNumPages, getPageOptions, hideModal,
    parseDate,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import {AdminPages} from "./AdminNav";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import { Patient } from "../../types/Patient";
import PatientAPI from "../../network/PatientAPI";
import { PatientManagementModal } from "../modals/PatientManagementModal";
import { PatientInfoModal } from "../modals/PatientInfoModal";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";


const ITEMS_PER_PAGE = 25;

interface PatientManagementState {
    patientInformation?;
    patients: Patient[]
    showLoading?
    showInBetween?
    filter?:{
        SelectedPatient
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    patientQueryResults:Patient[]
    race
    gender
    ethnicity
    states
    countries
    facilities
    customPatientFields?
    portalPatients
}

export class PatientManagement extends React.Component<any, PatientManagementState> {
    private patientInfoRef: any;
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            patients: [],
            patientInformation: {} as Patient,
            filter: {SelectedPatient:[],
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            patientQueryResults: [] as any,
            race: [],
            gender: [],
            ethnicity: [],
            states: [],
            countries: [],
            facilities: [],
            customPatientFields: [],
            portalPatients: []
        }
        // this.modifyPatient = this.modifyPatient.bind(this);
        this.patientInfoRef = React.createRef();
    }


    componentDidMount() {
        document.title = 'Patient Management Page';
        this.setState({showLoading: true}, () => {
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ race: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ gender: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicity: data });
            });
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
                this.setState({facilities: data.authorizedFacilities});
            });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data});
            });
            CustomFieldsAPI.getAllCustomFields().then(data =>{
                this.setState({
                    customPatientFields: data.data.customPatientFields,
                  });
            })
            PatientAPI.getPatientsFromPatientPortal().then(async data => {
                this.setState({portalPatients: data.patients})
            })
            this.queryPatients(1)
        })
    }

    queryPatients(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await PatientAPI.filterPatients(this.state.filter);
                if (results.data.length === 0) {
                    sweetalert.fire({
                      icon: "info",
                      title: "",
                      text: "No patients were returned",
                    });
                  }

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Patient data at this time', icon: 'error'})
                }
                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    patientQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                    portalPatients: results.data
                })
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        })
    }


    modifyPatient(patient){
        if(!patient || JSON.stringify(patient) === '{}' || !patient.patientInfo || JSON.stringify(patient.patientInfo) === '{}' || !patient.patientInfo.CustomFieldData || JSON.stringify(patient.patientInfo.CustomFieldData) === '{}'){
            return sweetalert.fire({icon: 'error', title: '', text: `Patient Information must be complete`})
        }

        let patientInformationCopy = JSON.parse(JSON.stringify(patient.patientInfo)); 

        patientInformationCopy.uid = patientInformationCopy.CustomFieldData.uid ? patientInformationCopy.CustomFieldData.uid : null;
        patientInformationCopy.firstName = patientInformationCopy.CustomFieldData['First Name'] ? patientInformationCopy.CustomFieldData['First Name'] : null;
        patientInformationCopy.middleName = patientInformationCopy.CustomFieldData['Middle Name'] ? patientInformationCopy.CustomFieldData['Middle Name'] : null;
        patientInformationCopy.lastName = patientInformationCopy.CustomFieldData['Last Name'] ? patientInformationCopy.CustomFieldData['Last Name'] : null;
        patientInformationCopy.email = patientInformationCopy.CustomFieldData.Email ? patientInformationCopy.CustomFieldData.Email : null;
        patientInformationCopy.phoneNumber = patientInformationCopy.CustomFieldData.Phone ? patientInformationCopy.CustomFieldData.Phone : null;
        patientInformationCopy.dateOfBirth = patientInformationCopy.CustomFieldData['Date of Birth'] ? patientInformationCopy.CustomFieldData['Date of Birth'] : null;
        patientInformationCopy.streetAddress = patientInformationCopy.CustomFieldData.Address ? patientInformationCopy.CustomFieldData.Address : null;
        patientInformationCopy.streetAddress2 = patientInformationCopy.CustomFieldData['Address Cont.'] ? patientInformationCopy.CustomFieldData['Address Cont.'] : null;
        patientInformationCopy.city = patientInformationCopy.CustomFieldData.City ? patientInformationCopy.CustomFieldData.City : null;
        patientInformationCopy.state = patientInformationCopy.CustomFieldData.State ? this.state.states.find(f => f.label === patientInformationCopy.CustomFieldData.State).value : null;
        patientInformationCopy.county = patientInformationCopy.CustomFieldData.County ? patientInformationCopy.CustomFieldData.County : null;
        patientInformationCopy.zipcode = patientInformationCopy.CustomFieldData.Zipcode ? patientInformationCopy.CustomFieldData.Zipcode : null;
        patientInformationCopy.country = patientInformationCopy.CustomFieldData.Country ? this.state.countries.find(f => f.label === patientInformationCopy.CustomFieldData.Country).value : null;
        patientInformationCopy.genderID = patientInformationCopy.CustomFieldData.Gender ? this.state.gender.find(f => f.label === patientInformationCopy.CustomFieldData.Gender).value : null;
        patientInformationCopy.ethnicityID = patientInformationCopy.CustomFieldData.Ethnicity ? this.state.ethnicity.find(f => f.label === patientInformationCopy.CustomFieldData.Ethnicity).value : null;
        patientInformationCopy.raceID = patientInformationCopy.CustomFieldData.Race ? this.state.race.find(f => f.label === patientInformationCopy.CustomFieldData.Race).value : null;
        patientInformationCopy.guardianFirstName = patientInformationCopy.CustomFieldData['Guardian First Name'] ? patientInformationCopy.CustomFieldData['Guardian First Name'] : null;
        patientInformationCopy.guardianLastName = patientInformationCopy.CustomFieldData['Guardian Last Name'] ? patientInformationCopy.CustomFieldData['Guardian Last Name'] : null;
        patientInformationCopy.FacilityIDs = patientInformationCopy.CustomFieldData.FacilityIDs ? patientInformationCopy.CustomFieldData.FacilityIDs : null;

        delete patientInformationCopy.CustomFieldData.Address;
        delete patientInformationCopy.CustomFieldData.City;
        delete patientInformationCopy.CustomFieldData.Country;
        delete patientInformationCopy.CustomFieldData.County;
        delete patientInformationCopy.CustomFieldData['Date of Birth'];
        delete patientInformationCopy.CustomFieldData.Email;
        delete patientInformationCopy.CustomFieldData.Ethnicity;
        delete patientInformationCopy.CustomFieldData['First Name'];
        delete patientInformationCopy.CustomFieldData.Gender;
        delete patientInformationCopy.CustomFieldData['Last Name'];
        delete patientInformationCopy.CustomFieldData['Middle Name'];
        delete patientInformationCopy.CustomFieldData.Phone;
        delete patientInformationCopy.CustomFieldData.Race;
        delete patientInformationCopy.CustomFieldData.State;
        delete patientInformationCopy.CustomFieldData.Zipcode;
        delete patientInformationCopy.CustomFieldData.uid;
        delete patientInformationCopy.CustomFieldData['Guardian First Name'];
        delete patientInformationCopy.CustomFieldData['Guardian Last Name'];
        delete patientInformationCopy.CustomFieldData.FacilityIDs;
      
        this.setState({showLoading:true}, async() => {
            try{
                let result = await PatientAPI.editPatient(patientInformationCopy)
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: 'Patient saved'}).then(()=>{
                        this.setState({patientInformation: {}})
                        this.queryPatients(this.state.selected_page.value);
                    });
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({showLoading:false})
                }
            }
            catch (e) {
                console.log(e)
                this.setState({showLoading:false})
            }
        })
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/patient/csv",
                "Patient Management Data.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    changePage(page:number){
        let allPatients = this.state.patientQueryResults;
        let returnData = slicePages(allPatients, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderPatientMgmtFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "UID",
                    "key": "uid",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "First Name",
                    "key": "firstName",
                    type: FIELD_TYPE.TEXT,
                }, 
                {
                    label: "Last Name",
                    "key": "lastName",
                    type: FIELD_TYPE.TEXT,
                }, 
                { label: "Date of Birth", key: "dateOfBirth", type: FIELD_TYPE.DATE },
                // {
                //     label: "Gender",
                //     "key": "genderID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.gender,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Ethnicity",
                //     "key": "ethnicityID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.ethnicity,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Race",
                //     "key": "raceID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.race,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    render() {

        // console.log('PatientMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <PatientInfoModal 
                ref={this.patientInfoRef}
                fromPatientMgmt={true}
                submit={(e) => {
                    this.modifyPatient(e);
                }}
                selectedPatient={this.state.patientInformation}
                portalPatients={this.state.portalPatients}
                states={this.state.states}
                gender={this.state.gender}
                ethnicity={this.state.ethnicity}
                race={this.state.race}
                countries={this.state.countries}
                facilities={this.state.facilities}
                patientFields={this.state.customPatientFields.sort((a,b) => a.Order - b.Order)}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Patient Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Patient Management</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderPatientMgmtFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline "}
                                            onClick={ () => {
                                                this.setState({
                                                    selected_page: {label: 1, value: 1}}, () => {
                                                    this.queryPatients(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                </div>
                            </div>
                        </main>
                    </div>



                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold stickToTop">
                                    <h4 className="text-center text-md-left">Patients
                                    <button className={"ml-2 d-none d-md-inline btn btn-outline-primary"} onClick={() => this.handleExportToCSV()} >Export to CSV </button>
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                    <h4 style={{ float: "right" }} aria-label="Total Records" role="alert">Total: {this.state.patientQueryResults.length}</h4>
                                    <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                    <div className=" align-middle float-right pages ">
                                        <Select
                                            isSearchable={true}
                                            placeholder={"1"}
                                            noOptionsMessage={() => "No option"}
                                            aria-label="Table Page Number"
                                            value={this.state.selected_page}
                                            onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                () => this.changePage(e.value))}
                                            className={"state_select"}
                                            options={this.state.page_options}
                                        />
                                    </div>
                                    </section>    
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                    <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Name", key: "",
                                            rawFormat: (val) => {
                                                return <a href={`/admin/PatientRecord/${val ? val.uid : null}`} 
                                                >{val.firstName} {val.lastName}</a>
                                            },
                                            popoverText: "Click to sort by Name"
                                        },
                                        {
                                            label: "UID", key: "uid",
                                            popoverText: "Click to sort by UID"
                                        },
                                        {
                                            label: "Date of Birth", key: "dateOfBirth",
                                            popoverText: "Click to sort by Date of Birth", formatFunc: parseDate
                                        },
                                        {
                                            label: "Email", key: "email",
                                            popoverText: "Click to sort by Email"
                                        },
                                        {
                                            label: "Phone #", key: "phoneNumber",
                                            popoverText: "Click to sort by Phone #"
                                        },
                                        {
                                            label: "City", key: "city",
                                            popoverText: "Click to sort by City"
                                        },
                                        {
                                            label: "State", key: "state",
                                            popoverText: "Click to sort by State"
                                        },
                                    ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                        this.useSorter(col);
                                    })} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}