import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectOptions,
  getBoolSelectVal,
  getEmailReportToOptions,
  getEmailReportToVal,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import { Service } from "../../types/Service";
import { ServicesManagementModal } from "../modals/ServicesManagementModal";
import NetworkUtil from "../../network/NetworkUtil";
import ServicesAPI from "../../network/ServicesAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import SystemAPI from "../../network/SystemAPI";
import {AdminPages} from "./AdminNav";
import PaginationTool from "../PaginationTool";
import AdminAPI from "../../network/AdminAPI";
import PaymentAPI from "../../network/PaymentAPI";

const ITEMS_PER_PAGE = 25;

interface ServicesManagementState {
    showLoading:boolean
    selectedService:Service
    serviceQueryResults:Service[]
    tableData:{}[]
    filter?:{
        Name
        IsEnabled
        ShowInsurance
        IsLabService
    }
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    serviceNames:{label,value}[]
    pageCustomQuestions?
    tests?
    branding
    brandingID?
    isGlobal
    pricing?
}

export class ServicesManagement extends React.Component<
  any,
  ServicesManagementState
> {
  constructor(props) {
    super(props);

        this.state = {
            showLoading: false,
            selectedService: {} as Service,
            tableData: [] as any,
            serviceQueryResults: [] as any,
            filter: {Name:[],IsEnabled:[],ShowInsurance:[], IsLabService:[]},
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            serviceNames:[],
            pageCustomQuestions: [],
            branding: [],
            isGlobal: false
    };

    this.createOrModifyService = this.createOrModifyService.bind(this);
  }

  componentDidMount() {
    document.title = 'Services Management Page';
    this.loadServiceNames();
  }

  loadServiceNames(){
    this.setState({showLoading: true}, async ()=>{
        SystemAPI.getCustomQuestions(AdminPages.Service).then(res =>{
            this.setState({pageCustomQuestions:res.questions})
        })
        ServicesAPI.getServiceNames().then(data => {
            this.setState({serviceNames: data.data})
        })
        .catch(e => {
            return sweetalert.fire({icon: 'info', title: '', text: "No Services found."})
        })
        SystemAPI.getBranding().then(data => {
            this.setState({branding: data.branding})
        })
        AdminAPI.getProductIDofUser().then(data => {
            this.setState({brandingID: data.brandingID, isGlobal: data.isGlobal})
        })

        SystemAPI.getAllTests().then(data => {
            this.setState({tests: data})
        })

        PaymentAPI.getPaymentPricing().then(data => {
            let formattedPricing = data.pricing.map(m => {return {label: `${m.Name} - ${m.Description} - ${m.Cost}`, value: m.ID}})
            this.setState({pricing: formattedPricing})
        })
        this.queryServicesData(1);
    });
}

queryServicesData(page:number){
  this.setState({showLoading: true}, async () => {
      let results = await ServicesAPI.filterServices({
          filter: {Name: this.state.filter.Name, IsEnabled: this.state.filter.IsEnabled, ShowInsurance: this.state.filter.ShowInsurance, IsLabService: this.state.filter.IsLabService}
      });
      if(results.data.length < 1){
          this.setState({showLoading: false, tableData: [], serviceQueryResults: null})
          return sweetalert.fire({title: '', text: 'No Services returned', icon: 'info'})
      }
      if(!results.success){
          this.setState({showLoading: false})
          return sweetalert.fire({title: '', text: 'Unable to filter Services data at this time', icon: 'error'})

      }


      let names = await ServicesAPI.getServiceNames();
      if(!names.success){
          this.setState({showLoading: false})
          return sweetalert.fire({icon: 'info', title: '', text: "No Services found."})

      }

      this.setState({
          tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
          serviceQueryResults: results.data,
          page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
          showLoading: false,
          serviceNames: names.data
      });
  });
}

createOrModifyService(service:Service){
  this.setState({showLoading:true}, async() => {
      if(service.ID === null){
          let response = await ServicesAPI.createService(service)
          if(!response.success){
              let msg = "Unable to create Service at this time.";
              if(response.reason){
                  msg = response.reason
              }
              this.setState({showLoading: false})
              return sweetalert.fire({icon: 'error', title: "", text: msg});

          }
      } else {
          let response = await ServicesAPI.editService(service)
          if(!response.success){
              let msg = "Unable to edit Service at this time.";
              if(response.reason){
                  msg = response.reason
              }
              this.setState({showLoading: false})
              return sweetalert.fire({icon: 'error', title: "", text: msg});

          }
      }

      sweetalert.fire({icon: 'success', title: '', html: '<h3>Service saved!</h3>  <p>Please click on Service Name in table to add/update Questionnaire and Well Plate Settings.</p>'})
          .then(()=>{
              this.setState({
                  selectedService: {
                      ID: null,
                      Name: "",
                      IsEnabled: getBoolSelectVal(0),
                      IsLabService: getBoolSelectVal(0),
                      ShowInsurance: getBoolSelectVal(0),
                      ShowFileUpload: getBoolSelectVal(0),
                      EmailReportTo: getEmailReportToVal(0),
                      HtmlTemplate: "",
                      ImportantInformation: "",
                      InformedConsent: "",
                      Summary: "",
                      ReleaseOfInformation: "",
                      ROIConsentAuth: "", 
                      CustomFieldData: null,
                      PlatingDirection: 0,
                      WellplateSettings: null,
                      ProductID: null,
                      EnablePayments: getBoolSelectVal(0),
                      PricingIDs: null
                  } as Service
              }, () => {
                  this.loadServiceNames();
                  this.queryServicesData(this.state.selected_page.value);
              });
          });
  });
}


  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/services/csv",
        "Services Management Data.xlsx",
        { filter: this.state.filter }      );
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.serviceQueryResults;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderServicesFilterFields(){
    return (
        <FilterCard fields={[
            {
                label: "Name",
                "key": "Name",
                type: FIELD_TYPE.SELECT,
                options: this.state.serviceNames,
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true
            }, {
                label: "Is Enabled",
                "key": "IsEnabled",
                type: FIELD_TYPE.SELECT,
                options: getBoolSelectOptions(),
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true
            }, {
                label: "Show Insurance",
                "key": "ShowInsurance",
                type: FIELD_TYPE.SELECT,
                options: getBoolSelectOptions(),
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true
            },{
                label: "Is Lab Service",
                "key": "IsLabService",
                type: FIELD_TYPE.SELECT,
                options: getBoolSelectOptions(),
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true
            },
        ]} filterChanged={(e)=> this.setState({ filter: e })} />
    )
}

useSorter(col:Column){
  let sorter = new Sorter<any>()

  this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
}


  render() {

    // console.log('ServiceMgmt state', this.state)
    return (
      <React.Fragment>
        <PaginationTool />
          <Overlay show_loading={this.state.showLoading} />
          <ServicesManagementModal
              pricing={this.state.pricing}
              brandingID={this.state.brandingID}
              isGlobal={this.state.isGlobal}
              branding={this.state.branding}
              tests={this.state.tests}
              selectedService={this.state.selectedService}
              onSubmit={(service) => {
                  hideModal(ServicesManagementModal.ID);
                  this.createOrModifyService(service);
              }}
              pageCustomQuestions={this.state.pageCustomQuestions}
          />

          <div className="container-fluid ">
              <div className={"row"}>
                  <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                      <main id="main-content" tabIndex={-1} aria-label="Service Management">
                          <div className="card mb-2">
                              <div className="card-header verlag-bold">
                                  <h4>Service Management</h4>
                              </div>
                              <div className="card-body">
                                  {this.renderServicesFilterFields()}
                              </div>
                              <div className="card-footer">
                                  <button className={"btn immySubmitButtonOutline"}
                                          onClick={ () => {
                                              this.setState({
                                                  selected_page: {label: 1, value: 1}}, () => {
                                                  this.queryServicesData(this.state.selected_page.value)
                                              })
                                          }}
                                  >Search</button>
                                  <button className="btn btn-outline-success float-right"
                                          onClick={()=> this.setState({
                                              selectedService: {
                                                  ID: null,
                                                  Name: "",
                                                  IsEnabled: getBoolSelectVal(0),
                                                  IsLabService: getBoolSelectVal(0),
                                                  ShowInsurance: getBoolSelectVal(0),
                                                  ShowFileUpload: getBoolSelectVal(0),
                                                  EmailReportTo: getEmailReportToVal(0),
                                                  HtmlTemplate: "",
                                                  ImportantInformation: "",
                                                  InformedConsent: "",
                                                  Summary: "",
                                                  ReleaseOfInformation: "",
                                                  ROIConsentAuth: "",
                                                  CustomFieldData: null,
                                                  PlatingDirection: 0,
                                                  WellplateSettings: null,
                                                  ProductID: null,
                                                  EnablePayments: getBoolSelectVal(0),
                                                  PricingIDs: null
                                              } as Service
                                          },()=>{
                                              showModalNoOutsideClick(ServicesManagementModal.ID)
                                          })}
                                  >Create New</button>
                              </div>
                          </div>
                      </main>
                  </div>

                  {
                      this.state.tableData &&
                      this.state.tableData.length > 0 &&

                      <div className="col-md-12 pt-2">
                          <div className="card mt-2" style={{marginBottom: '4rem'}}>
                              <div className="card-header verlag-bold">
                                  <h4 className="text-center text-md-left">Services
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.serviceQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className="align-middle float-right pages ">
                                          <Select
                                              isSearchable={true}
                                              placeholder={"1"}
                                              noOptionsMessage={() => "No option"}
                                              value={this.state.selected_page}
                                              aria-label="Table Page Number"
                                              onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                  () => this.changePage(e.value))}
                                              className={"state_select"}
                                              options={this.state.page_options}
                                          />
                                        </div>
                                    </section>
                                    <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button>
                                  </h4>
                              </div>
                              <div className="card-body p-0 m-0 table-responsive">
                                  <SimpleTable table_style='tableFixHead' columns={[
                                      {
                                          label: "Name", key: "Name",
                                          rawFormat: (val) => {
                                              return <a href={"#"} className={'tableNameLinkColor'} onClick={() => {
                                                  this.setState({ selectedService: val },
                                                      () => showModalNoOutsideClick(ServicesManagementModal.ID))
                                              }}>{val.Name}</a>
                                          },
                                          popoverText: "Click to sort by Name"
                                      },
                                      {
                                          label: "Enabled", key: "IsEnabled",
                                          popoverText: "Click to sort by Enabled",
                                          rawFormat: (val) => {
                                              let string = getBoolSelectVal(val.IsEnabled)
                                              return <p>{string.label}</p>
                                          }
                                      },
                                      {
                                          label: "Show Insurance", key: "ShowInsurance",
                                          popoverText: "Click to sort by Show Insurance",
                                          rawFormat: (val) => {
                                              let string = getBoolSelectVal(val.ShowInsurance)
                                              return <p>{string.label}</p>
                                          }
                                      },
                                      {
                                          label: "Show File Upload", key: "ShowFileUpload",
                                          popoverText: "Click to sort by Show File Upload",
                                          rawFormat: (val) => {
                                              let string = getBoolSelectVal(val.ShowFileUpload)
                                              return <p>{string.label}</p>
                                          }
                                      },
                                      {
                                          label: "Multiple Tests", key: "AllowMultipleTests",
                                          popoverText: "Click to sort by MultipleTests Insurance",
                                          rawFormat: (val) => {
                                              let string = getBoolSelectVal(val.AllowMultipleTests)
                                              return <p>{string.label}</p>
                                          }
                                      },
                                      {
                                        label: "Is Lab Service?", key: "IsLabService",
                                        popoverText: "Click to sort by Is Lab Service",
                                        rawFormat: (val) => {
                                            let string = getBoolSelectVal(val.IsLabService)
                                            return <p>{string.label}</p>
                                        }
                                        },
                                        // {
                                        //     label: "Email Report To", key: "EmailReportTo",
                                        //     popoverText: "Click to sort by Email Report To",
                                        //     rawFormat: (val) => {
                                        //         let string = getEmailReportToVal(val.EmailReportTo)
                                        //         return <p>{string.label}</p>
                                        //     }
                                        // },
                                      {
                                          label: "Tests", key: "Tests",
                                          popoverText: "Click to sort by Tests",
                                          rawFormat: (val) => {
                                              let testLabels = val.Tests && val.Tests.map(t => {
                                                  let test = this.state.tests.find(f => f.value === t);
                                                  if(test){
                                                      return test.label;
                                                  }
                                              })
                                              return testLabels && testLabels.map(m => (
                                                  <p className="mb-0">{m}</p>
                                              ))
                                          }
                                      }
                                  ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                      this.useSorter(col);
                                  })} />
                              </div>
                          </div>
                      </div>
                  }
              </div>
          </div>
      </React.Fragment>
  )
  }
}
