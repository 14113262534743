import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectVal,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import { Result } from "../../types/Result";
import { ResultsManagementModal } from "../modals/ResultsManagementModal";
import NetworkUtil from "../../network/NetworkUtil";
import ResultsAPI from "../../network/ResultsAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import SystemAPI from "../../network/SystemAPI";
import { AdminPages } from "./AdminNav";

const ITEMS_PER_PAGE = 25;

interface ResultsManagementState {
  showLoading: boolean;
  selectedResult: Result;
  resultQueryResults;
  tableData: {}[];
  filter?: {
    Result;
    Description;
    Test;
    IsEnabled;
    AllowNonStandardResultValue;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  resultsList: { label; value }[];
  descList: { label; value }[];
  testsList: { label; value }[];
  pageCustomQuestions?

}

export class ResultsManagement extends React.Component<
  any,
  ResultsManagementState
> {
  
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedResult: {} as Result,
      tableData: [] as any,
      resultQueryResults: [] as any,
      filter: { Result: [], Description: [], Test: [], IsEnabled: [], AllowNonStandardResultValue: [] },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
      resultsList: [],
      descList: [],
      testsList: [],
      pageCustomQuestions: [],
    };

    this.createOrModifyResult = this.createOrModifyResult.bind(this);
  }

  componentDidMount() {
    document.title = 'Results Management Page';
    this.loadResultData();
    SystemAPI.getCustomQuestions(AdminPages.Result).then(res =>{
      this.setState({pageCustomQuestions:res.questions})
  })
  }

  loadResultData() {
    this.setState({ showLoading: true }, async () => {
      ResultsAPI.getAllResultsData()
        .then((data) => {
            let resultsList = data.data.results.map(result => ({label: result.Value, value: result.Value}))
            let descList = data.data.results.map(result => ({label: result.Description, value: result.Description}))
            let testsList = data.data.tests.map(test => ({label: test.Name, value: test.Name}))
          this.setState({ resultsList: resultsList, descList: descList, testsList: testsList, showLoading: false });
        })
        .catch((e) => {
          return sweetalert.fire({
            icon: "info",
            title: "",
            text: "No Services found.",
          });
        });
    });
  }

  queryResultsData(page: number) {
    this.setState({ showLoading: true }, async () => {
      let results = await ResultsAPI.filterResults({
        filter: {
          Result: this.state.filter.Result,
          Description: this.state.filter.Description,
          Test: this.state.filter.Test,
          IsEnabled: this.state.filter.IsEnabled,
          AllowNonStandardResultValue: this.state.filter.AllowNonStandardResultValue
        },
      });
      if(results.data[0].length < 1){
        this.setState({showLoading: false, tableData: [], resultQueryResults: null})
        return sweetalert.fire({title: '', text: 'No Results returned', icon: 'info'})
      }
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: "Unable to filter Results data at this time",
          icon: "error",
        });
      }

      this.setState({
        tableData: slicePages(results.data[0], page, ITEMS_PER_PAGE),
        resultQueryResults: results.data,
        page_options: getPageOptions(getNumPages(results.data[0], ITEMS_PER_PAGE)),
        showLoading: false,
      });
    });
  }

  createOrModifyResult(result: Result) {
    this.setState({ showLoading: true }, async () => {
      if (result.ID === null) {
        let response = await ResultsAPI.createResult(result);
        if (!response.success) {
          let msg = "Unable to create Result at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
            allowOutsideClick: false
          }).then((response) => {
            if(response.isConfirmed){
              window.location.reload()
            }
          });
        }
      } else {
        let response = await ResultsAPI.editResult(result);
        if (!response.success) {
          let msg = "Unable to edit Result at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
            allowOutsideClick: false
          }).then((response) => {
            if(response.isConfirmed){
              window.location.reload()
            }
          });
        }
      }

      sweetalert
        .fire({ icon: "success", title: "", text: "Result saved" })
        .then(() => {
          this.setState(
            {
              selectedResult: {
                ID: null,
                Value: null,
                Description: null,
                EnableHighlighting: null,
                HighlightColor: null,
                HighlightColorLabel: null,
                CustomFieldData: null,
                IsEnabled: null, 
                AllowNonStandardResultValue: null
              } as Result,
            },
            () => {
              this.loadResultData();
              this.queryResultsData(this.state.selected_page.value);
            }
          );
        });
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/results/csv",
        "Result Management Data.xlsx",
        { filter: this.state.filter });
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.resultQueryResults[0];
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderResultsFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "Result",
            key: "Result",
            type: FIELD_TYPE.SELECT,
            options: this.state.resultsList,
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Description",
            key: "Description",
            type: FIELD_TYPE.SELECT,
            options: this.state.descList,
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Test",
            key: "Test",
            type: FIELD_TYPE.SELECT,
            options: this.state.testsList,
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Is Enabled",
            key: "IsEnabled",
            type: FIELD_TYPE.SELECT,
            options: [{label: 'Yes', value: [1]}, {label: 'No', value: [0]}],
            isMapped: true,
            textType: "text",
            isClearable: true,
            isFilter: true
          },
          {
            label: "Is Non-Standard Result Value",
            key: "AllowNonStandardResultValue",
            type: FIELD_TYPE.SELECT,
            options: [{label: 'Yes', value: [1]}, {label: 'No', value: [0]}],
            isMapped: true,
            textType: "text",
            isFilter: true
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {

    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <ResultsManagementModal
          pageCustomQuestions={this.state.pageCustomQuestions}
          selectedResult={this.state.selectedResult}
          onSubmit={(result) => {
            hideModal(ResultsManagementModal.ID);
            this.createOrModifyResult(result);
          }}
        />

        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Result Management">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>Result Management</h3>
                  </div>
                  <div className="card-body">
                    {this.renderResultsFilterFields()}
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryResultsData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-success float-right"
                      onClick={() =>
                        this.setState(
                          {
                            selectedResult: {
                              ID: null,
                              Value: "",
                              Description: "",
                              EnableHighlighting: null,
                              HighlightColor: null,
                              HighlightColorLabel: null,
                              CustomFieldData: null,
                              IsEnabled: null,
                              AllowNonStandardResultValue:null
                            } as Result,
                          },
                          () => {
                            showModalNoOutsideClick(ResultsManagementModal.ID);
                          }
                        )
                      }
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </main>
            </div>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2" style={{marginBottom: '4rem'}}>
                  <div className="card-header verlag-bold stickToTop">
                    <h4 className="text-center text-md-left">
                      Results
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                          Total: {(this.state.resultQueryResults[0]).length }
                        </h4>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: "Result",
                          key: "Value",
                          popoverText: "Click to Sort By Result",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#"}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  this.setState({ selectedResult: val }, () =>
                                    showModalNoOutsideClick(
                                      ResultsManagementModal.ID
                                    )
                                  );
                                }}
                              >
                                {val.Value}
                              </a>
                            );
                          },
                        },
                        {
                          label: "Description",
                          key: "Description",
                          popoverText: "Click to Sort By Description",
                        },
                        {
                          label: "Highlight Color",
                          key: "HighlightColorLabel",
                          popoverText: "Click to Sort By Highlight Color",
                          rawFormat: (val) => {
                            return <p>{val.HighlightColorLabel ? val.HighlightColorLabel : 'None'}</p>;
                          }
                        },
                        {
                          label: "Tests",
                          key: "Name",
                          popoverText: "Click to Sort By Tests",
                          rawFormat: (val) => {
                            return <p className="testsString">{val.Name}</p>;
                          }
                        },
                        {
                          label: "Is Enabled?",
                          key: "IsEnabled",
                          popoverText: "Click to Sort By Is Enabled",
                          rawFormat: (val) => {
                            let string = getBoolSelectVal(val.IsEnabled);
                            return <p>{string.label}</p>;
                          },
                        },
                        {
                          label: "Non-Standard?",
                          key: "AllowNonStandardResultValue",
                          popoverText: "Click to Sort By Allow Non Standard Result Value",
                          rawFormat: (val) => {
                            let string = getBoolSelectVal(val.AllowNonStandardResultValue);
                            return <p>{string.label}</p>;
                          },
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}