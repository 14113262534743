import { FIELD_TYPE, FilterPart } from "../components/FilterCard";
import { CustomFields, CustomFieldType } from "../types/CustomFieldType";
import { sweetalert } from "../App";
import { isValidEmail } from "../util/ValidationUtil";

export class CustomFieldBuilder {
  public static getFilterParts(customFields: CustomFieldType[]): FilterPart[] {
    let parts: FilterPart[] = [];
    for (let customField of customFields) {
      if (customField.Options.isActive) {
        if (customField.FieldTypeID === CustomFields.TEXT_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.TEXT,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            maxLength: customField.Options.maxLength
              ? customField.Options.maxLength
              : null,
          });
        } else if (customField.FieldTypeID === CustomFields.TEXTAREA_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.TEXT_AREA,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            maxLength: customField.Options.maxLength
              ? customField.Options.maxLength
              : null,
          });
        } else if (customField.FieldTypeID === CustomFields.SELECT_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.SELECT,
            options: customField.Options.options
              ? customField.Options.options
              : [],
            isMulti: customField.Options.isMulti
              ? customField.Options.isMulti
              : false,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            isMapped: true,
          });
        } else if (customField.FieldTypeID === CustomFields.NUMBER_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.NUMBER,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            max: customField.Options.max,
            min: customField.Options.min,
          });
        } else if (customField.FieldTypeID === CustomFields.DATE_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.DATE,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            dateWarning: true,
          });
        }
        // else if(customField.FieldTypeID === CustomFields.TIME_FIELD){
        //
        //     parts.push({
        //         label:customField.DisplayName,
        //         key:customField.DisplayName,
        //         type:"select",
        //         options:customField.Options.options ? customField.Options.options : [],
        //         isMulti:customField.Options.isMulti ? customField.Options.isMulti : false,
        //         popoverText:customField.Options.popoverText ? customField.Options.popoverText : '',
        //         isMapped: true,
        //     })
        // }
        // else if(customField.FieldTypeID === CustomFields.DATETIME_FIELD){
        //
        //     parts.push({
        //         label:customField.DisplayName,
        //         key:customField.DisplayName,
        //         type:FIELD_TYPE.SELECT,
        //         options:customField.Options.options ? customField.Options.options : [],
        //         isMulti:customField.Options.isMulti ? customField.Options.isMulti : false,
        //         popoverText:customField.Options.popoverText ? customField.Options.popoverText : '',
        //         dateWarning: true
        //     })
        // }
        else if (customField.FieldTypeID === CustomFields.PHONE_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.PHONE,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            textType: "tel",
            pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
          });
        }
        else if (customField.FieldTypeID === CustomFields.EMAIL_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.EMAIL,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            maxLength: customField.Options.maxLength
              ? customField.Options.maxLength
              : null,
          });
        }
      }
    }
    return parts;
  }

  public static validateCustomFields(customFields, customQuestions, record = null, patientSelected=false) {
    
    let invalid = false;
    let symptomatic = false;
    let recordCD;

    if (record) {
      recordCD = new Date(record.CreatedDate);
      recordCD = recordCD.getTime() as any;
    }

    for (const q of customQuestions) {
      let fieldCD = new Date(q.CreatedDate);
      fieldCD = fieldCD.getTime() as any;

      if (
        q.Options.isRequired &&
        q.Options.isActive &&
        (record ? recordCD >= fieldCD : true)
      ) {
        if (customFields && q.DisplayName in customFields) {
          for (const [key, value] of Object.entries(customFields)) {
            if (key === q.DisplayName) {
              if (
                !value ||
                (Array.isArray(value) && value.length < 1) ||
                String(value).trim().length < 1
              ) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  html: patientSelected ? `<h3>${q.DisplayName} value is required</h3><p>Please update via Patient Management</p>` : `<p>${q.DisplayName} value is required</p>`
                });
                invalid = true;
                break;
              }
            }
          }
        } else {
          sweetalert.fire({
            icon: "error",
            title: "",
            html: patientSelected ? `<h3>${q.DisplayName} value is required</h3><p>Please update via Patient Management</p>` : `<p>${q.DisplayName} value is required</p>`
          });
          invalid = true;
          break;
        }
      }

      if (customFields) {
        for (const [key, value] of Object.entries(customFields)) {

          if (key === q.DisplayName) {
            //special check for Onset Symptoms Date

            if (
              (q.DisplayName === "Are you symptomatic?" ||
                q.DisplayName ===
                  "Are you symptomatic as defined by the CDC?") &&
              value === "True"
            ) {
              symptomatic = true;
            }
            if (symptomatic) {
              if (
                !value &&
                (q.DisplayName === "Onset Symptoms Date" ||
                  q.DisplayName ===
                    "If yes, what was the date of symptom onset?")
              ) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `Onset Symptoms Date must be provided if Symptomatic is True`,
                });
                invalid = true;
                break;
              }
              if (
                !customFields.hasOwnProperty("Onset Symptoms Date") &&
                !customFields.hasOwnProperty(
                  "If yes, what was the date of symptom onset?"
                )
              ) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `Onset Symptoms Date must be provided if Symptomatic is True`,
                });
                invalid = true;
                break;
              }
              if (
                symptomatic &&
                (q.DisplayName === "Onset Symptoms Date" ||
                  q.DisplayName ===
                    "If yes, what was the date of symptom onset?") &&
                new Date(value as Date) > new Date()
              ) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `Onset Symptoms Date cannot be in the future`,
                });
                invalid = true;
                break;
              }
            }
            if (record) {
              if (
                symptomatic &&
                (q.DisplayName === "Onset Symptoms Date" ||
                  q.DisplayName ===
                    "If yes, what was the date of symptom onset?") &&
                new Date(value as Date) < new Date(record.PatientDOB)
              ) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `Onset Symptoms Date cannot be before Patient date of birth`,
                });
                invalid = true;
                break;
              }
            }

            //TEXT_FIELD//
            if (
              (q.FieldTypeID === CustomFields.TEXT_FIELD ||
              q.FieldTypeID === CustomFields.TEXTAREA_FIELD) && value && String(value).length > 0
            ) {
              //check if value length is greater than max length
              if (String(value).length > Number(q.Options.maxLength)) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be less than ${q.Options.maxLength} characters`,
                });
                invalid = true;
                break;
              }
            }

            //EMAIL_FIELD//

            if (
              q.FieldTypeID === CustomFields.EMAIL_FIELD && value && String(value).length > 0
            ) {
              //check if email is valid
              if (!isValidEmail(String(value))) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} must be a valid email`,
                });
                invalid = true;
                break;
              }
            }

            //NUMBER_FIELD//
            if (q.FieldTypeID === CustomFields.NUMBER_FIELD && value) {
              //check if value less than min or greater than max
              if (Number(value) < q.Options.min) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be greater than ${q.Options.min}`,
                });
                invalid = true;
                break;
              }
              if (Number(value) > q.Options.max) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be less than ${q.Options.max}`,
                });
                invalid = true;
                break;
              }
            }

            //PHONE_FIELD//
            if (q.FieldTypeID === CustomFields.PHONE_FIELD && value && String(value).length > 0) {
              let phoneRegex =
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
              if (!phoneRegex.test(String(value))) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be a valid phone number`,
                });
                invalid = true;
                break;
              }
            }

            //DATE_FIELD//
            if (q.FieldTypeID === CustomFields.DATE_FIELD && value) {
              let valueDate = new Date(value as Date)
              let now = new Date()
              if (valueDate.getTime() > now.getTime()) {
                if(q.Options.allowFutureDate){
                  invalid = false;
                }
                else{
                  sweetalert.fire({
                    icon: "error",
                    title: "",
                    text: `${q.DisplayName} cannot be in the future`,
                  });
                  invalid = true;
                }
                break;
              }
            }
          }
        }
      }

      if (invalid) {
        break;
      }
    }

    return invalid;
  }

  // Patient Info Modal Fields
   public static getPatientQuestions(gender, states, ethnicity, race, countries) {
    return [
      {
        label: "First Name",
        key: "PatientFirstName",
        type: FIELD_TYPE.TEXT,
        textType: "text",
        maxLength: 50,
      },
      {
        label: "Middle Initial",
        key: "PatientMiddleName",
        type: FIELD_TYPE.TEXT,
        textType: "text",
        maxLength: 50,
      },
      {
        label: "Last Name",
        key: "PatientLastName",
        type: FIELD_TYPE.TEXT,
        textType: "text",
        maxLength: 50,
      },
      {
        label: "Email to Receive Results",
        key: "PatientEmail",
        type: FIELD_TYPE.TEXT,
        textType: "email",
        maxLength: 100,
      },
      {
        label: "Confirm Email",
        key: "confirmEmail",
        type: FIELD_TYPE.TEXT,
        textType: "email",
        maxLength: 100,
      },
      {
        label: "Gender",
        key: "PatientGenderID",
        type: FIELD_TYPE.SELECT,
        options: gender,
        isMapped: true,
      },
      {
        label: "Ethnicity",
        key: "PatientEthnicityID",
        type: FIELD_TYPE.SELECT,
        options: ethnicity,
        isMapped: true,
      },
      {
        label: "Race",
        key: "PatientRaceID",
        type: FIELD_TYPE.SELECT,
        options: race,
        isMapped: true,
      },
      {
        label: "Date of Birth",
        key: "PatientDOB",
        type: FIELD_TYPE.DATE,
        dateWarning: true,
      },
      {
        label: "State",
        key: "PatientState",
        type: FIELD_TYPE.SELECT,
        options: states,
        isMapped: true,
      },
      {
        label: "Country",
        key: "PatientCountry",
        type: FIELD_TYPE.SELECT,
        options: countries,
        isMapped: true,
      },
      {
        label: "Street Address",
        key: "PatientAddress",
        type: FIELD_TYPE.TEXT,
        maxLength: 500,
      },
      {
        label: "City",
        key: "PatientCity",
        type: FIELD_TYPE.TEXT,
        maxLength: 100,
      },
      { label: "Zip", key: "PatientZip", type: FIELD_TYPE.TEXT, maxLength: 50 },
      {
        label: "County",
        key: "PatientCounty",
        type: FIELD_TYPE.TEXT,
        maxLength: 100,
      },
      {
        label: "Cell Phone",
        key: "PatientPhone",
        type: FIELD_TYPE.PHONE,
        textType: "tel",
        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
      },
      {
        label: "Guardian First Name",
        key: "GuardianFirstName",
        type: FIELD_TYPE.TEXT,
        maxLength: 50,
      },
      {
        label: "Guardian Last Name",
        key: "GuardianLastName",
        type: FIELD_TYPE.TEXT,
        maxLength: 500,
      },
    ];
  }
}

