import { Bar } from 'react-chartjs-2';
import { getSuperscripts } from './utils';

export const getBarChart = (profile, title, tests, lineColor) => {

    const annotations = tests.map((data, index) => {
        return{
            type: 'line',
            yMin: data.PrescriptionRecommendationThreshold,
            yMax: data.PrescriptionRecommendationThreshold,
            xMin: -0.45 + index,
            xMax: 0.45 + index,
            borderColor: 'rgb(120,120,120)',
            borderWidth: 2,
            key: `annotations-${index}`,
        }
    })
    return (
    <Bar 
        height={window.innerWidth < 850 ? 300 : 200}
        data={profile}
        title={title}
        options={{
            layout: {
                padding: {
                    left: 40,
                    
                }
            },
            // responsive: true,
            // maintainAspectRatio: false,
            plugins: {
                annotation: {
                    annotations
                  },
                title: {
                    display: true,
                    text: title,
                    font: {size: 25, weight: 'bold'},
                    padding: {bottom: 20, top: 20}
                },
                legend: {
                    display: false
                },
                datalabels: {
                    labels: {
                        date: {
                            align: 110,
                            anchor: 'start',
                            // offset: 100,
                            color: 'rgb(120,120,120)',
                            font: { size: 16, weight: 'bold'},
                            padding: {left: -10},
                            rotation: 300,
                            formatter: function(date, context){
                                //@ts-ignore
                                return context.dataset.date
                            }
                        }
                    }
                }
            },
            scales: {
                y: {
                    border: {
                        width: 6,
                        color: lineColor,
                        z: 1
                    },
                    grid: {
                        lineWidth: 1,
                        color: 'white'
                    },
                    type: "logarithmic",
                    min: 0.999,
                    // min: 0,
                    max: 100000000,
                    ticks: {
                    callback: (value, index, values) => {
                        return getSuperscripts(value)
                        },
                        font: {
                        size: 18,
                        weight: 'bold'
                        }
                    }
                },
                x: {
                    type: "category",
                    border: {
                                width: 5,
                                color: lineColor,
                                z: 1
                            },
                    grid: {
                        tickLength: 0,
                        lineWidth: 1,
                        color: 'black'
                    },
                    ticks: {
                        padding: 65,
                        font: {
                            size: 30,
                            weight: 'bold'
                        }
                    }
                },
            }
        }} 
    />
    )
}

