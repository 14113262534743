import React from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { hideModal } from "../../util/FormatUtil";
import Parser from "html-react-parser";

interface InformedConsentProps {
  legalName: string;
  selectedServices?
}

interface InformedConsentState{
    arrowClicked?
}

class InformedConsentModal extends React.Component<InformedConsentProps, InformedConsentState> {
  public static readonly ID = "informed_consent";
  constructor(props) {
    super(props);
    this.state = {
      arrowClicked: -1
    };
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (

        <React.Fragment>
        <div
          className="modal fade form_modal"
          id={InformedConsentModal.ID}
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
          aria-label="Informed Consent"
        >
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mb-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>
                                Informed Consent
                              </h4>
                            </div>
                            <div className="col-2">
                              <button style={{outline: 'none'}} type="button" className="close pr-4"
                                    aria-label="Close" onClick={() => hideModal(InformedConsentModal.ID)}>
                                <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                              </button> 
                            </div>
                          </div>               
                        </div>

                        <div className="card-body">
                            <div className="accordion" id="informedConsent">
                            {this.props.selectedServices && this.props.selectedServices.map((ss, i) => (
                                    <div className="card mt-2">
                                        <div className="card-header" id={'heading'+i}>
                                                <button
                                                    className={"collapsed verlag-bold"}
                                                    data-toggle="collapse"
                                                    data-target={'#collapse'+i}
                                                    onClick={() =>{
                                                        if(this.state.arrowClicked === i){
                                                            this.setState({
                                                                arrowClicked: -1
                                                            })
                                                        }
                                                        else{
                                                            this.setState({
                                                                arrowClicked: i
                                                            })
                                                        }
                                                    }}
                                                    aria-expanded={"false"}
                                                    aria-controls={'collapse'+i}
                                                >
                                                  <div className="row">
                                                    <div className="col-10">  
                                                      <h4>
                                                        {ss.Name}
                                                      </h4>
                                                    </div>
                                                    <div className="col-2">
                                                      {this.state.arrowClicked == i ? (
                                                          <AiOutlineUp
                                                          className={"float-right"}
                                                          size={30}
                                                          data-toggle="collapse"
                                                          data-target={'#collapse'+i}
                                                          role="button"
                                                          aria-expanded={i == 0 ? "true" : "false"}
                                                          aria-controls={'collapse'+i}
                                                          />
                                                      ) : (
                                                          <AiOutlineDown
                                                          className={"float-right"}
                                                          size={30}
                                                          data-toggle="collapse"
                                                          data-target={'#collapse'+i}
                                                          role="button"
                                                          aria-expanded={i == 0 ? "true" : "false"}
                                                          aria-controls={'collapse'+i}
                                                          />
                                                      )}
                                                    </div>
                                                  </div>
                                                </button>
                                        </div>

                                        <div
                                        className={"collapse show verlag-light responsive-p"} style={{borderBottom: '1px solid lightgrey'}}
                                            id={'collapse'+i} aria-labelledby={'heading'+i} data-parent="#informedConsent"
                                        >
                                            <div className="card-body">
                                                {ss.InformedConsent && Parser(ss.InformedConsent)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div
                    className="card-footer"
                    style={{
                      backgroundColor: "transparent",
                      borderTop: "none",
                    }}
                  >
                    <button
                      className={"btn btn-primary float-right mb-3"}
                      onClick={() => hideModal(InformedConsentModal.ID)}
                    >
                      OK
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>








    //   <ModalMessage
    //     title={"Informed Consent"}
    //     ID={InformedConsentModal.ID}
    //     onSubmit={() => hideModal(InformedConsentModal.ID)}
    //     message={
    //         this.props.selectedService?.Name
        //   <p>
        //     <ol>
        //       <li>
        //         Authorization and Consent for Covid-19 Diagnostic Testing:
        //         <p>
        //           I voluntarily consent and authorize {this.props.legalName} to
        //           conduct collection, testing, and analysis for the purposes of
        //           a COVID-19 diagnostic test. I acknowledge and understand that
        //           my COVID-19 diagnostic test will require the collection of an
        //           appropriate sample by my healthcare provider through a
        //           nasopharyngeal swab, oral swab, or other recommended
        //           collection procedures. I understand that there are risks and
        //           benefits associated with undergoing a diagnostic test for
        //           COVID-19 and there may be a potential for false positive or
        //           false negative test results. I assume complete and full
        //           responsibility to take appropriate action with regards to my
        //           test results. Should I have question or concerns regarding my
        //           results, or a worsening of my condition, I shall promptly seek
        //           advice and treatment from an appropriate medical provider.
        //         </p>
        //       </li>
        //       <li>
        //         Patient Rights and Privacy Practices:
        //         <ol>
        //           <li>
        //             Notice of Privacy Practices and Patient Rights:{" "}
        //             {this.props.legalName}’s Notice of Privacy Practices
        //             describes how it may use and disclose your protected health
        //             information to carry out treatment, initiate and obtain
        //             payment, conduct health care operations and for other
        //             purposes that are permitted or required by law. To review a
        //             copy of your rights as a patient and {this.props.legalName}
        //             ’s Notice of Privacy Practices please click{" "}
        //             <a
        //               target={"_blank"}
        //               href={"/documents/osdh_privacy_policies.pdf"}
        //             >
        //               here
        //             </a>
        //             . I acknowledge that {this.props.legalName} has provided me
        //             with a copy of {this.props.legalName}’s Notice of Privacy
        //             Practices.
        //           </li>
        //           <li>
        //             Disclosure to Government Authorities: I acknowledge and
        //             agree that {this.props.legalName} may disclose my test
        //             results and associated information to appropriate county,
        //             state, or other governmental and regulatory entities as may
        //             be permitted by law.
        //           </li>
        //         </ol>
        //       </li>
        //       <li>
        //         Release:
        //         <p>
        //           To the fullest extent permitted by law, I hereby release,
        //           discharge and hold harmless, {this.props.legalName},
        //           including, without limitation, any its respective officers,
        //           directors, employees, representatives and agents from any and
        //           all claims, liability, and damages, of whatever kind or
        //           nature, arising out of or in connection with any act or
        //           omission relating to my COVID-19 diagnostic test or the
        //           disclosure of my COVID-19 test results.
        //         </p>
        //         <p>
        //           By selecting the ACKNOWLEDGEMENT during the registration
        //           process for COVID-19 Diagnostic Testing at{" "}
        //           {this.props.legalName}, I acknowledge and agree that I have
        //           read, understand, and agreed to the statements contained
        //           within this form. I have been informed about the purpose of
        //           the COVID-19 diagnostic test, procedures to be performed,
        //           potential risks and benefits, and associated costs. I have
        //           been provided an opportunity to ask questions before
        //           proceeding with a COVID-19 diagnostic test and I understand
        //           that if I do not wish to continue with the collection,
        //           testing, or analysis of a COVID-19 diagnostic test, I may
        //           decline to receive continued services. I have read the
        //           contents of this form in its entirety and voluntarily consent
        //           to undergo diagnostic testing for COVID-19.
        //         </p>
        //       </li>
        //     </ol>
        //   </p>
        // }
    //     buttonText={"OK"}
    //   />
    );
  }
}

export default InformedConsentModal;
