import React from "react";
import Overlay from "../Overlay";
import SamplesAPI from "../../network/SamplesAPI";
import AdminAPI from "../../network/AdminAPI";
import BatchesAPI from "../../network/BatchesAPI";

interface AdminHomePageState {
  showLoading?: boolean;
  facilitySamplesArray
  facilities
  batches
}

export default class AdminHomePage extends React.Component<
  any,
  AdminHomePageState
> {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      facilitySamplesArray: [],
      facilities: [],
      batches: []
    };
  }

  componentDidMount(): void {
    document.title = 'Admin Home Page';
    document.body.style.backgroundImage = ``;
    document.body.style.backgroundColor = '#FFFFFF'
    this.setState({ showLoading: true }, async () => {
      AdminAPI.getFacilitiesForSubmissionForm().then(data => {
        this.setState({facilities: data.authorizedFacilities})
      })
      BatchesAPI.getAllBatches().then(data => {
        this.setState({batches: data, showLoading: false})
    })
      SamplesAPI.getAllSamplesForAdminHome().then(data => {
        this.setState({
          facilitySamplesArray: data.facilitySamplesArray,
          showLoading: false,
        });
      })
      
    });
  }

  getCard(title, number){
    return (
      <div className="col-12 col-md-6 col-xl-3">
        <div className="card mt-2 text-center font-weight-bold">
          <div className="card-header verlag-bold">
            <h4>{title}</h4>
          </div>
          <div className="card-body" style={{fontSize: '3rem'}}>
            {number || number === 0 ? number : 'N/A'}
          </div>
        </div>
      </div>
  )}

  //total number with TestOrderedDate present but TestResultDate null
  getAwaitingResult(array){
    return array.filter(f => f.TestOrderedDate && !f.TestResultDate).length;
  }

  // total number with HasPhysicianReviewed in Samples table = 0 and and ResultApprovedDate in Batches table is present 
  getAwaitingReview(array){
    let numberAwaitingReview = 0;
    let notReviewedSamples = array.filter(f => !f.HasPhysicianReviewed);
    if(notReviewedSamples && notReviewedSamples.length > 0){
      notReviewedSamples.map(s => {
        if(s.BatchID && this.state.batches && this.state.batches.length > 0 && this.state.batches.find(f => f.ID === s.BatchID).ResultApprovedDate){
          numberAwaitingReview ++
        }
      })
    }
    return numberAwaitingReview;
  }

  //total number with Reported Date
  getCompleted(array){
    return array.filter(f => f.HasPhysicianReviewed && f.TestResultDate).length;
  }
      

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {

      // console.log('AdminHomePage state', this.state)

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <main id="main-content" tabIndex={-1} aria-label="Home Page Samples Statistics">
          <div className="container-fluid">
              <div className={"row"}>
                <div className="col-12 pt-2">
                  {this.state.facilitySamplesArray && this.state.facilitySamplesArray.length > 0 ? this.state.facilitySamplesArray.map((f, index) => {
                    return (
                    <article>
                    <div className={index > 0 ? "card mt-5" : "card mt-2"}>
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-12 text-center">
                            <h4>Samples Stats for {this.state.facilities && this.state.facilities.length > 0 ? this.state.facilities.find(fa => fa.ID === f[0].FacilityID).FacilityName : ''}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {this.getCard('Submitted', f.length)}{/* total number of samples */}
                      {this.getCard('Awaiting Result', this.getAwaitingResult(f))}
                      {this.getCard('Awaiting Review', this.getAwaitingReview(f))}
                      {this.getCard('Completed', this.getCompleted(f))}
                    </div>
                    </article>
                    )
                  }
                    ) : (
                    <div className="col-12 pt-2">
                      <div className="card mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h5>No sample stats to show</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                  }
                </div>
              </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
