import NetworkUtil from "./NetworkUtil"
import Price from "../types/Price"

export default class PricingAPI {
    public static async getAllPricingForSelect():Promise<{success:boolean,reason?:string,data:{label:string,value:number}[]}>{
        return NetworkUtil.makeGet("/api/admin/pricing/allForSelect");
    }
    public static async getAllPricingTypesForSelect():Promise<{success:boolean,reason?:string,data:{label:string,value:number}[]}>{
        return NetworkUtil.makeGet("/api/admin/pricing/allPriceTypesForSelect");
    }
    public static async getAllPricing(body):Promise<{success:boolean,reason?:string,data:Price[]}>{
        return NetworkUtil.makePost("/api/admin/pricing/all", body);
    }
    public static async newPricing(body):Promise<{success:boolean,reason?:string,price:Price}>{
        return NetworkUtil.makePost("/api/admin/pricing/new",body);
    }
    public static async editPricing(body):Promise<{success:boolean,reason?:string,price:Price}>{
        return NetworkUtil.makePost("/api/admin/pricing/edit",body);
    }
    public static async queryPricing(body):Promise<{success:boolean,reason?:string,price:Price}>{
        return NetworkUtil.makePost("/api/admin/pricing/details",body);
    }
    public static async filterPricing(filter:{filter:{SelectedPrice}}):Promise<{success:boolean,reason?:string,data:Price[]}>{
        return NetworkUtil.makePost("/api/admin/pricing/filter",filter);
    }
}