import React from "react";
import Select from "react-select";
import {Result} from "../../types/Result";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {buildRow, getBoolSelectOptions, getBoolSelectVal} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";
import GetField from "../../customfields/GetField";
import { CustomFieldBuilder } from "../../customfields/CustomFieldBuilder";

interface ResultsManagementState{
    selectedResult:Result
    customFieldData?
}
interface ResultsManagementProps{
    selectedResult
    onSubmit?
    pageCustomQuestions?
}
export class ResultsManagementModal extends React.Component<ResultsManagementProps, ResultsManagementState>{
    public static ID = "servicesmanagement";
    constructor(props) {
        super(props);
        this.state = {
            selectedResult: {
                ID: null,
                Value: null,
                Description: null,
                EnableHighlighting: null,
                HighlightColor: null,
                HighlightColorLabel: null,
                IsEnabled: null,
                AllowNonStandardResultValue: null,
                CustomFieldData: null
            } as Result
        } as ResultsManagementState
    }

    componentDidMount(): void {
        $(`#${ResultsManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps: Readonly<ResultsManagementProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedResult)){
            let result = nextProps.selectedResult;
            this.setState({
                selectedResult: {
                    ID: result.ID,
                    Value: result.Value,
                    Description: result.Description,
                    EnableHighlighting: result.EnableHighlighting,
                    HighlightColor: result.HighlightColor,
                    HighlightColorLabel: result.HighlightColorLabel,
                    IsEnabled: result.IsEnabled,
                    AllowNonStandardResultValue: result.AllowNonStandardResultValue,
                    CustomFieldData: result.CustomFieldData && JSON.stringify(result.CustomFieldData) !== '{}' ? JSON.parse(result.CustomFieldData) : null,
                    CreatedDate: result.CreatedDate ? result.CreatedDate : new Date()
                } as Result
            });
       
        }
    }

    render(){
        return(
            <div className="modal fade form_modal" id={ResultsManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Result Management</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {buildRow("Result",
                                <input className={"form-control"}
                                       id="Result"
                                       maxLength={50}
                                       readOnly={this.state.selectedResult.ID != null}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Result"}
                                       aria-label="Result"
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedResult: {
                                                   ...prevState.selectedResult,
                                                   Value: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedResult.Value}
                                />
                            )}
                            {buildRow("Description",
                                <input
                                    className={"form-control"}
                                    id="Description"
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Description"}
                                    aria-label="Description"
                                    onChange={(e) => this.setState((prevState) => ({
                                        selectedResult: {
                                            ...prevState.selectedResult,
                                            Description: e.target.value
                                        }
                                    }))}
                                    value={ this.state.selectedResult.Description }
                                />
                            )}
                            {buildRow("Highlight Enabled?",
                                <Select
                                isSearchable={true}
                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                noOptionsMessage={() => "No option"}
                                aria-label="Highlight Enabled?"
                                value={ this.state.selectedResult.ID ? getBoolSelectVal(this.state.selectedResult.EnableHighlighting) :  
                                        (this.state.selectedResult.EnableHighlighting === 1 || this.state.selectedResult.EnableHighlighting === 0) ? getBoolSelectVal(this.state.selectedResult.EnableHighlighting) :
                                        null }
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedResult: {
                                        ...prevState.selectedResult,
                                        EnableHighlighting: e.value ? 1 : 0,
                                        HighlightColor: null
                                    }
                                }))}
                                className={"state_select"}
                                options={getBoolSelectOptions()}
                            />
                            )}
                            {buildRow("Highlight Color",
                                <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                isDisabled={!this.state.selectedResult.EnableHighlighting}
                                aria-label="Highlight Color"
                                value={ {label: this.state.selectedResult.HighlightColorLabel, value: this.state.selectedResult.HighlightColor} }
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedResult: {
                                        ...prevState.selectedResult,
                                        HighlightColor: e.value,
                                        HighlightColorLabel: e.label
                                    }
                                }))}
                                className={"state_select"}
                                options={[{label: 'Red', value: 'text-danger'}, {label: 'Yellow', value: 'text-warning'}, {label: 'Green', value: 'text-success'},{label: 'Blue', value: 'text-info'}]}
                            />
                            )}
                            {buildRow("Is Enabled",
                                <Select
                                isSearchable={true}
                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                noOptionsMessage={() => "No option"}
                                aria-label="Is Enabled?"
                                value={ this.state.selectedResult.ID ? getBoolSelectVal(this.state.selectedResult.IsEnabled):
                                    this.state.selectedResult.IsEnabled === 1 ||  this.state.selectedResult.IsEnabled === 0 ? getBoolSelectVal(this.state.selectedResult.IsEnabled) :
                                    null }
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedResult: {
                                        ...prevState.selectedResult,
                                        IsEnabled: e.value ? 1 : 0
                                    }
                                }))}
                                className={"state_select"}
                                options={getBoolSelectOptions()}
                            />
                            )}
                            {/* {buildRow("Is Non-Standard Result?",
                                <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                value={ this.state.selectedResult.ID ? getBoolSelectVal(this.state.selectedResult.AllowNonStandardResultValue):
                                    this.state.selectedResult.AllowNonStandardResultValue === 1 ||  this.state.selectedResult.AllowNonStandardResultValue === 0 ? getBoolSelectVal(this.state.selectedResult.AllowNonStandardResultValue) :
                                    null }
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedResult: {
                                        ...prevState.selectedResult,
                                        AllowNonStandardResultValue: e.value ? 1 : 0
                                    }
                                }))}
                                className={"state_select"}
                                options={getBoolSelectOptions()}
                            />
                            )} */}
                             {/*Custom Field Data*/}

                             {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedResult ? this.state.selectedResult : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedResult: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                if(isBlank(this.state.selectedResult.Value) ||
                                    isOnlyWhitespace(this.state.selectedResult.Value)
                                ){
                                    return sweetalert.fire("Attention","Result cannot be empty.", "error")
                                }
                                if(isBlank(this.state.selectedResult.Description) || isOnlyWhitespace(this.state.selectedResult.Description)){
                                    return sweetalert.fire("Attention", "Result Description cannot be empty", "error")
                                }
                                if( this.state.selectedResult.EnableHighlighting === null && this.state.selectedResult.ID === null ){
                                    return sweetalert.fire("Attention", "Please select a value for Hightlighting Enabled", "error")
                                } 
                                if(this.state.selectedResult.EnableHighlighting === 1 && this.state.selectedResult.HighlightColor === null){
                                    return sweetalert.fire("Attention", "Please select a value for Highlight Color", "error")
                                }
                                if( this.state.selectedResult.IsEnabled === null && this.state.selectedResult.ID === null){
                                    return sweetalert.fire("Attention", "Please select a value for Is Enabled", "error")
                                }
                                let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedResult.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedResult)

                                if(invalid){
                                    return;
                                }


                                this.props.onSubmit(this.state.selectedResult);
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}