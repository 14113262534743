import FilterCard, {FIELD_TYPE} from "../FilterCard";
import React from "react";
import InsuranceType from "../../types/Insurance";
import {sweetalert} from "../../App";
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {InsuranceImageModal} from "../modals/InsuranceImageModal";
import { isMobile } from 'react-device-detect';
import {InsuranceCardUploadModal} from "../modals/InsuranceCardUploadModal";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {SampleInsuranceCardModal} from "../modals/SamplesInsuranceCardModal";
import {hideModal, showModalNoOutsideClick} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import InBetweenOverlay from "../InBetweenOverlay";


interface InsuranceModalProps{
    submit:(e) => void,
    attachClearStateFunc?:(func) => void
    imageSaved?
    handleImageSaved?:(e) => void
    states?
}

interface InsuranceModalState{
    insuranceInfo?
    showLoading:boolean
    filter?
    isDupe:boolean
    captureClicked:boolean
    showInBetween:boolean
    frontImageURL?
    backImageURL?
    frontCardImgFile?
    backCardImgFile?
    // frontFileForDupeCheck?:File
    // backFileForDupeCheck?:File
}
export class InsuranceModal extends React.Component<InsuranceModalProps, InsuranceModalState>{
    public static ID = "insurance";
    private videoRef: any;
    constructor(props) {
        super(props);
        this.state ={
            isDupe: false,
            captureClicked: false,
            showLoading: false,
            showInBetween: false
        }
        this.videoRef = React.createRef();
        this.assignClearState = this.assignClearState.bind(this);
    }

    componentDidMount(): void {
        $(`#${InsuranceModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;

    }

    clearState = () =>{
        const frontUpload = document.querySelector('#FrontCardCapture') as any;
        frontUpload.value = '';
        const backUpload = document.querySelector('#BackCardCapture') as any;
        backUpload.value = '';
        if(isMobile){
            const frontUploadMobile = document.querySelector('#FrontCardCaptureMobile') as any;
            frontUploadMobile.value = '';
            const backUploadMobile = document.querySelector('#BackCardCaptureMobile') as any;
            backUploadMobile.value = '';
        }
        
        this.clearFilterState();
        this.setState({
            frontImageURL: null,
            backImageURL: null,
            frontCardImgFile: null,
            backCardImgFile: null,
        })
        this.props.handleImageSaved(false)
    }

    clearFiles = () =>{
        this.setState({
            isDupe:false
        })
    }

    shouldComponentUpdate(nextProps: Readonly<InsuranceModalProps>, nextState: Readonly<InsuranceModalState>, nextContext: any): boolean {
        if(this.props.attachClearStateFunc)
            this.props.attachClearStateFunc(this.clearState)
        return true;
    }

    onUpload = (state, e) => {
        if(e.target){
            if(state === 'frontCardImgFile'){
                this.setState({frontImageURL: URL.createObjectURL(e.target.files[0])} as any)
            }
            else if(state === 'backCardImgFile'){
                this.setState({backImageURL: URL.createObjectURL(e.target.files[0])} as any)
            }
            this.setState({[state]: e.target.files[0]} as any)
        }
        else{
            if(state === 'frontCardImgFile'){
                this.setState({frontImageURL: URL.createObjectURL(e)} as any)
            }
            else if(state === 'backCardImgFile'){
                this.setState({backImageURL: URL.createObjectURL(e)} as any)
            }
            this.setState({[state]: e} as any)
        }
        this.setState({isDupe:false})
        // if(isMobile){
        //     this.props.handleImageSaved(true)
        // }
    }

    sampleInsuranceCardUpload(){
        if(!isMobile){
            sweetalert.fire({
                title: 'Add New Insurance Card Images',
                showCloseButton: true,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Upload',
                denyButtonText: `Capture`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({showInBetween: true})
                    showModalNoOutsideClick(InsuranceCardUploadModal.ID)
                }
                else if(result.isDenied){
                    this.setState({captureClicked: true, showInBetween: true})
                    showModalNoOutsideClick(InsuranceImageModal.ID)
                }
                else if(result.isDismissed){
                }
            })
        }
        else{
            showModalNoOutsideClick(InsuranceCardUploadModal.ID)
        }
    }



    handleSave(){
        this.setState({showLoading: true})

        let isDupe = this.dupeCheck()
        if(isDupe){
            this.setState({showLoading: false})
            return
        }
        
        let stateCopy = JSON.parse(JSON.stringify(this.state.insuranceInfo))
        stateCopy.FrontImgUrl = this.state.frontImageURL ? this.state.frontImageURL : null
        stateCopy.BackImgUrl = this.state.backImageURL ? this.state.backImageURL : null
        stateCopy.FrontCardImgFile = this.state.frontCardImgFile ? this.state.frontCardImgFile : null
        stateCopy.BackCardImgFile =  this.state.backCardImgFile ? this.state.backCardImgFile : null

        //validate insuranceInfo
        if(!InsuranceModal.validateInsurance(stateCopy)){
            this.setState({showLoading: false})
            return
        }

        // //pass back to parent
        this.props.submit(stateCopy)

        hideModal(InsuranceModal.ID)
        this.setState({showLoading: false})

    }


    public static validateInsurance(i:InsuranceType):boolean{
        let insuranceValidation = {
            FirstName: i.FirstName,
            LastName: i.LastName,
            SSN: i.SSN,
            DOB: i.DOB,
            MRN: i.MRN,
            AddressLine1: i.AddressLine1,
            AddressLine2: i.AddressLine2,
            City: i.City,
            State: i.State,
            Zip: i.Zip,
            FrontCardImgFile: i.FrontCardImgFile,
            BackCardImgFile: i.BackCardImgFile,
        };

        let validator = new Validator<any>().withValidation("FirstName", Validators.requireNotBlankAndLength(100, "Insured Person's First Name"))
            .withValidation("LastName", Validators.requireNotBlankAndLength(100, "Insured Person's Last Name"))
            .withValidation("SSN", Validators.requireNotBlankAndSSN("Insured Person's Social Security Number"))
            .withValidation("DOB", Validators.requireDOB(150, "Insured Person's Date of Birth"))
            if(i.MRN && i.MRN.trim().length > 0){
             validator = validator.withValidation("MRN", Validators.requireNotBlankAndLength(45, "Insured Person's Medical Record Number"))
            }
            validator = validator.withValidation("AddressLine1", Validators.requireNotBlankAndLength(100, "Insured Person's Address"))
            if(i.AddressLine2 && i.AddressLine2.trim().length > 0){
                validator = validator.withValidation("AddressLine2", Validators.requireNotBlankAndLength(100, "Insured Person's Address Cont."))
            }
            validator = validator.withValidation("City", Validators.requireNotBlankAndLength(100, "Insured Person's City"))
            .withSimpleValidation("State", Validators.requireNonNullValidator("Insured Person's State"))
            .withSimpleValidation("Zip", Validators.requireZip("Insured Person's Zip Code"))
                .withSimpleValidation("FrontCardImgFile", Validators.requireNonNullValidator("Insured Person's Front Card Image"))
                .withSimpleValidation("BackCardImgFile", Validators.requireNonNullValidator("Insured Person's Back Card Image"))

            let validationResponse = validator.validate(insuranceValidation);
            if(!validationResponse.success) {
            sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
            return false
        }

        return true;
    }

    closeInBetween(){
        this.setState({showInBetween: false})
    }

    dupeCheck(){
        if(!this.state.frontCardImgFile || !this.state.backCardImgFile){
            sweetalert.fire({icon: 'error', title: '', text: 'Please add insurance card images'});
            return true
        }
        if(this.state.frontCardImgFile.name === this.state.backCardImgFile.name){
            sweetalert.fire({icon: 'error', title: '', text: 'Duplicate images detected'});
            return true
        }
    }


    render() {
            return  (
                <React.Fragment>
                    <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
                    <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100005} />
                    <SampleInsuranceCardModal 
                        frontImage={this.state.frontImageURL} 
                        backImage={this.state.backImageURL} 
                        handleLoading={() => {}} 
                        closeInBetween={() => this.closeInBetween()}
                    />
                    <InsuranceImageModal 
                        onFrontImgChange={(e) => this.onUpload('frontCardImgFile', e)} 
                        onBackImgChange={(e) => this.onUpload('backCardImgFile', e)} 
                        clearFiles={() => this.clearFiles()} 
                        handleLoading={() =>{}} 
                        handleImageSavedButton={(e) => this.props.handleImageSaved(e)} 
                        captureClicked={this.state.captureClicked }
                        closeInBetween={() => this.closeInBetween()}
                        clearImageState={() => this.setState({frontCardImgFile: null, frontImageURL: null, backCardImgFile: null, backImageURL: null})}
                    />
                    <InsuranceCardUploadModal 
                        frontUpload={(e) => this.onUpload('frontCardImgFile', e)} 
                        backUpload={(e) => this.onUpload('backCardImgFile', e)} 
                        clearFiles={() => this.clearFiles()} 
                        handleLoading={() =>{}} 
                        isDupe={this.state.isDupe} 
                        handleImageSavedButton={(e) => this.props.handleImageSaved(e)} 
                        closeInBetween={() => this.closeInBetween()}
                        clearImageState={() => this.setState({frontCardImgFile: null, frontImageURL: null, backCardImgFile: null, backImageURL: null})}
                    />
               
                    <div className="modal fade form_modal" id={InsuranceModal.ID} tabIndex={-1} style={{zIndex: 100004}} role="dialog" aria-label="Insurance Information"
                        aria-hidden="true" >
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 pt-2">
                                                <div className="card mt-2">
                                                    <div className="card-header verlag-bold">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <h4>
                                                                    Insurance Information
                                                                </h4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                        aria-label="Close" onClick={() => hideModal(InsuranceModal.ID)}>
                                                                    <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                    <p>If using insurance, please provide insurance information for the insurance holder.</p>
                                                        <FilterCard
                                                            passClearStateFunc={this.assignClearState}
                                                            fields={[
                                                                {label: "First Name", "key": "FirstName", type: FIELD_TYPE.TEXT, maxLength: 100, required: true},
                                                                {label: "Last Name", "key": "LastName", type: FIELD_TYPE.TEXT, maxLength: 100, required: true},
                                                                {label: "SSN", "key": "SSN", textType:"password",  type: FIELD_TYPE.TEXT, maxLength: 45, togglePassword: true, required: true},
                                                                {label: "Date of Birth", "key": "DOB", type: FIELD_TYPE.DATE, dateWarning: true, required: true},
                                                                {label: "Medical Record Number (optional)", "key": "MRN", type: FIELD_TYPE.TEXT, maxLength: 100},
                                                                {label: "State", "key": "State", type: FIELD_TYPE.SELECT, options: this.props.states, isMapped: true, isMulti: false, required: true},
                                                                {label: "Address", "key": "AddressLine1", type: FIELD_TYPE.TEXT, maxLength: 100, required: true},
                                                                {label: "Address Cont.", "key": "AddressLine2", type: FIELD_TYPE.TEXT, maxLength: 100},
                                                                {label: "City", "key": "City", type: FIELD_TYPE.TEXT, maxLength: 100, required: true},
                                                                {label: "Zip", "key": "Zip", type: FIELD_TYPE.TEXT, maxLength: 45, required: true},
                                                            ]} filterChanged={(e) => this.setState({insuranceInfo: e})}
                                                        />

                                                        {isMobile ?
                                                            <>
                                                            <div className="form-group row">
                                                                <label htmlFor={"FrontCardCaptureMobile"} className={"col-sm-4 col-form-label"}>Front of Insurance Card</label>
                                                                <div className={"col-sm-8 p-0 m-0 "}>
                                                                    <input type="file" id={"FrontCardCaptureMobile"} onChange={(e) => {
                                                                        // this.setState({frontFileForDupeCheck: e.target.files[0]})
                                                                        this.onUpload('frontCardImgFile', e)
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor={"BackCardCaptureMobile"} className={"col-sm-4 col-form-label"}>Back of Insurance Card</label>
                                                                <div className={"col-sm-4 p-0 m-0 "}>
                                                                    <input type="file" id={"BackCardCaptureMobile"} onChange={(e) => {
                                                                        // this.setState({backFileForDupeCheck: e.target.files[0]})
                                                                        this.onUpload('backCardImgFile', e)
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            {/* {this.props.imageSaved ? <div className={'pt-2'}><AiOutlineCheckCircle color={'#14410B'} size={30}/><span className={'ml-2 text-success'} style={{fontSize: '1em'}}>Images Saved</span></div> : <div className="invisible"></div>} */}
                                                            </>
                                                        :

                                                            <div className={"form-group row"}>
                                                                <label htmlFor={"InsuranceCard"} className={"col-12 col-sm-4 col-form-label"}>Insurance Cards</label>
                                                                <button
                                                                    className={this.props.imageSaved ? 'col-12 col-sm-2 btn btn-success btn-sm pt-2' : 'd-none'}
                                                                    onClick={() => {this.setState({showInBetween: true}); showModalNoOutsideClick("SampleInsuranceCard")}}>View Images
                                                                </button>
                                                                <button
                                                                    className={this.props.imageSaved ? 'col-12 col-sm-2 btn btn-success btn-sm pt-2 ml-2' : 'col-8 col-sm-4 btn btn-success btn-sm pt-2'}
                                                                    onClick={() => this.sampleInsuranceCardUpload()}>Add
                                                                    New Images
                                                                </button>
                                                                {this.props.imageSaved ? <div className={'col-12 col-sm-3 pt-2'}><AiOutlineCheckCircle color={'#14410B'} size={30} /><span className={'ml-2 text-success'} style={{fontSize: '1em'}}>Images Saved</span></div> : <div className="invisible"></div>}
                                                            </div>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                            <button className={"btn btn-primary mr-auto"} onClick={() => {this.clearState(); hideModal(InsuranceModal.ID); this.props.submit(null)}}>No Insurance</button>
                                            <button className={"btn btn-primary"} onClick={() => this.handleSave()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

}