import React, {useCallback, useState, useLayoutEffect, CSSProperties} from "react";
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import Overlay from "../Overlay";
import Select from "react-select";
import {sweetalert} from "../../App";
import SimpleTable from "../tables/SimpleTable";
import {BatchSelect} from "./selects/BatchSelect";
import Batch from "../../types/Batch";
import TestsAPI from "../../network/TestsAPI";
import BatchesAPI from "../../network/BatchesAPI";
import ResultsAPI from "../../network/ResultsAPI";
import { isEmptyObject } from "jquery";
import ServicesAPI from "../../network/ServicesAPI";
import { buildRow, getDateOrNull, getIDFromLabel, getLabel, getLabelFromName, getNameFromAbbreviation, getNameFromID, getlabelsFromIDs, parseDate } from "../../util/FormatUtil";
import AdminAPI from "../../network/AdminAPI";
import FacilityType from "../../types/Facility";
import SamplesAPI from "../../network/SamplesAPI";
import { useCSVReader, lightenDarkenColor, formatFileSize, } from 'react-papaparse';
import NetworkUtil from "../../network/NetworkUtil";
import SystemAPI from "../../network/SystemAPI";
import GetField from "../../customfields/GetField";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { CustomFields } from "../../types/CustomFieldType";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import PhoneInput from 'react-phone-number-input';
import { isValidEmail } from "../../util/ValidationUtil";
import { isPossiblePhoneNumber } from "react-phone-number-input";



type SampleCSVImportProps = {};

// will need to update these hardcoded values on front and back so that template matches uploaded csv
// headers are on line 8

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
    zone: {
      alignItems: 'center',
      border: `2px dashed ${GREY}`,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: 20,
      marginBottom: 10
    } as CSSProperties,
    file: {
      background: 'linear-gradient(to bottom, #EEE, #DDD)',
      borderRadius: 20,
      display: 'flex',
      height: 120,
      width: 120,
      position: 'relative',
      zIndex: 10,
      flexDirection: 'column',
      justifyContent: 'center',
    } as CSSProperties,
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
    } as CSSProperties,
    size: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      marginBottom: '0.5em',
      justifyContent: 'center',
      display: 'flex',
    } as CSSProperties,
    name: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      fontSize: 12,
      marginBottom: '0.5em',
    } as CSSProperties,
    progressBar: {
      bottom: 14,
      position: 'absolute',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
    } as CSSProperties,
    zoneHover: {
      borderColor: GREY_DIM,
    } as CSSProperties,
    default: {
      borderColor: GREY,
    } as CSSProperties,
    remove: {
      height: 23,
      position: 'absolute',
      right: 6,
      top: 6,
      width: 23,
    } as CSSProperties,
  };
  

export const SampleCSVImport = ({}: SampleCSVImportProps) => {

    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(
      DEFAULT_REMOVE_HOVER_COLOR
    );

    const [firstTimeThrough, setFirstTimeThrough] = useState(true)
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false)
    const [services, setServices] = useState([])
    const [facilities, setFacilities] = useState([])
    const [showLoading, setShowLoading] = useState(true)
    const [tableData, setTableData] = useState([])
    const [allCustomQuestions, setAllCustomQuestions] = useState([])
    const [selectedService, setSelectedService] = useState(null)
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [importedCSVFacility, setImportedCSVFacility] = useState(null)
    const [importedCSVService, setImportedCSVService] = useState(null)
    const [importedCSVTests, setImportedCSVTests] = useState(null)
    const [importedCSVSpecimenSources, setImportedCSVSpecimenSources] = useState(null)


    const getData = async() =>  {
        AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
            setFacilities(data.authorizedFacilities)
        })
        CustomFieldsAPI.getAllCustomFields().then(data =>{
            let customQuestions = data.data.customQuestions;
            customQuestions = customQuestions.filter(cq => cq.Options.isActive)            
            let customPatientFields = data.data.customPatientFields;
            customPatientFields = customPatientFields.filter(cp => cp.Options.isActive)
            let combinedCustom = customQuestions.concat(customPatientFields);
            setAllCustomQuestions(combinedCustom)
        })
        ServicesAPI.getServicesForSubmissionForm().then((data) => {
            setServices(data.data)
            setShowLoading(false)
        });
    }

    useLayoutEffect(() => {
        getData();
    }, [])


    const handleExportToCSV = async () => {
        setShowLoading(true)
        await NetworkUtil.downloadCSV(
        "/api/admin/bulkSamplesCSVExport",
        `${importedCSVService.Name}Service Bulk Sample Export.xlsx`,
        { data: tableData } );
        setShowLoading(false);
    }

    const validateTable = async () => {
        setShowLoading(true)

        //check that facility has service
        let facility = importedCSVFacility && importedCSVFacility.value ? facilities.find(f => f.ID === importedCSVFacility.value) : null;
        if(!facility){
            setShowLoading(false)
            return sweetalert.fire({icon: 'error', title: '', text: `Importing Facility cannot be blank`})
            
        }
        let facilityServiceIDs = JSON.parse(facility.ServiceIDs)    

        if(!facilityServiceIDs.includes(importedCSVService.ID)){
            setShowLoading(false)
            return sweetalert.fire({icon: 'error', title: '', text: `${importedCSVService.Name} does not belong to ${facility.FacilityName}`})
        }

        if(!tableData || tableData.length < 1){
            setShowLoading(false)
            return sweetalert.fire("", "All required fields in table must be complete", "error")
        }

        let specimenIDs = [];
        let serviceQuestions = ['Tests', 'Specimen Source', 'Specimen ID', 'Lot', 'Memo', 'Collection Date', 'Test Ordered Date']

        let validated = true;
        for (let i = 0; i < tableData.length; i++) {
            for (const key in tableData[i]) {
                if (tableData[i].hasOwnProperty(key)) {
                    if(serviceQuestions.includes(key)){
                    continue;
                    }
                    if(!allCustomQuestions.find(f => f.DisplayName === key)){
                        setShowLoading(false)
                        return sweetalert.fire({icon: 'error', title: '', text: 'Something went wrong. Make sure you are using the latest Excel document.'})
                    }
                    validated = validate(i, key, allCustomQuestions.find(f => f.DisplayName === key))
                    if(!validated){
                        return;
                    }
                }
            }

            //Tests - required
            if(tableData[i]['Tests']){
                let splitStringArray;
                let testsArray = [];
                //check if array. if not, convert to array.
                if(!Array.isArray(tableData[i]['Tests']) && !Number(tableData[i]['Tests'])){
                    splitStringArray = tableData[i]['Tests'].split(","); 

                    
                    for (let j = 0; j < splitStringArray.length; j++) {
                        let result = getIDFromLabel(splitStringArray[j].trim(), importedCSVTests);
                        if(result){
                            testsArray.push({label: splitStringArray[j].trim(), value: result})
                        }
                        else{
                            setShowLoading(false)
                            return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: ${splitStringArray[j]}</b> is not valid. Please select valid tests from the dropdown.</p>`})
                        }
                    }
                    tableData[i]['Tests'] = testsArray;
                }
                else{
                    //check if array label matches name in importedCSVTests
                    for (let j = 0; j < tableData[i]['Tests'].length; j++) {
                        let valid = !!importedCSVTests.find(f => f.Name === tableData[i]['Tests'][j].label)
                        if(!valid){
                            setShowLoading(false)
                            return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: ${tableData[i]['Tests'][j].label}</b> is not valid. Please select valid tests from the dropdown.</p>`})
                        }
                    }
                }
            }
            else{
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Tests</b> cannot be blank</p>`})
            }

            //Specimen Source - required
            if(tableData[i]['Specimen Source']){
                if(!Number(tableData[i]['Specimen Source'])){
                    tableData[i]['Specimen Source'] = getIDFromLabel(tableData[i]['Specimen Source'].trim(), importedCSVSpecimenSources);
                }

            }
            else{
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Specimen Source</b> cannot be blank</p>`})
            }

            //Specimen ID - required for IsLabService = true
            if(importedCSVService.IsLabService && (!tableData[i]['Specimen ID'] || tableData[i]['Specimen ID'].length < 1)){
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Specimen ID</b> cannot be blank</p>`})
            }
            //Specimen ID - check for duplicates in tableData
            specimenIDs.push(tableData[i]['Specimen ID']);   
            
            //Collection Date - required
            if(!tableData[i]['Collection Date']){
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Collection Date</b> must be a valid date.</p>`})
            }
            if(new Date(tableData[i]['Collection Date']) > new Date()){
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Collection Date</b> cannot be in the future.</p>`})
            }

            //Test Ordered Date - required
            if(!tableData[i]['Test Ordered Date']){
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Test Ordered Date</b> must be a valid date.</p>`})
            }
            if(new Date(tableData[i]['Test Ordered Date']) > new Date()){
                setShowLoading(false)
                return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: Test Ordered Date</b> cannot be in the future.</p>`})
            }
        }

        //check for duplicate Specimen IDs 
        let duplicates = specimenIDs.filter((item, index) => specimenIDs.indexOf(item) !== index)
        if(duplicates.length > 0){
            setShowLoading(false)
            return sweetalert.fire({icon: 'error', title: '', text: "Duplicate Specimen IDs detected"});
        }

        //set up object to be sent to backend
        let tableDataForBackend = JSON.parse(JSON.stringify(tableData));

        submit(tableDataForBackend)

    }

    const submit = async(tableDataToSubmit) => {
        let facility = facilities.find(f => f.ID === importedCSVFacility.value)
        let facilityServiceIDs = JSON.parse(facility.ServiceIDs)
        if(!facilityServiceIDs.includes(importedCSVService.ID)){
            setShowLoading(false)
            return sweetalert.fire({icon: 'error', title: '', html: `<p><b>${importedCSVService.Name}</b> does not belong to <b>${facility.FacilityName}</b></p>`})
        }
        for (let i = 0; i < tableDataToSubmit.length; i++) {
            tableDataToSubmit[i]['Facility'] = importedCSVFacility;
            tableDataToSubmit[i]['Service'] = importedCSVService;
            for (const key in tableDataToSubmit[i]) {
                if (tableDataToSubmit[i].hasOwnProperty(key)) {
                    if(key === 'Tests'){
                        if(Array.isArray(tableDataToSubmit[i][key])){
                            tableDataToSubmit[i][key] = JSON.stringify(tableDataToSubmit[i][key].map(m => {return Number(m.value) ? m.value : importedCSVTests.find(t => m.value == t.Name).ID}));
                        }
                    }

                    if(key === 'Collection Date' || key === 'Test Ordered Date'){
                        tableDataToSubmit[i][key] = getDateOrNull(new Date(tableDataToSubmit[i][key]))

                    }

                    for (let j = 0; j < allCustomQuestions.length; j++) {
                        if(allCustomQuestions[j].DisplayName === key){
                            //covert date format
                            if(allCustomQuestions[j].FieldTypeID === CustomFields.DATE_FIELD){
                                tableDataToSubmit[i][key] = tableDataToSubmit[i][key] && new Date(tableDataToSubmit[i][key]) ? parseDate(new Date(tableDataToSubmit[i][key])) : null;
                            }
                            //convert phone
                            if(allCustomQuestions[j].FieldTypeID === CustomFields.PHONE_FIELD){
                                let justNumbers = tableDataToSubmit[i][key].replace(/\D/g, '');
                                tableDataToSubmit[i][key] = `+1${justNumbers}`;
                            }

                            if(allCustomQuestions[j].FieldTypeID === CustomFields.SELECT_FIELD){
                                if(allCustomQuestions[j].Options.isMulti){
                                    const temp = tableDataToSubmit[i][key].map(m => {return m.value});
                                    tableDataToSubmit[i][key] = tableDataToSubmit[i][key].map(m => {return m.value});
                                }
                                else{
                                    tableDataToSubmit[i][key] = tableDataToSubmit[i][key][0].value;
                                }
                            }
                        }
                    }
                }
            }   
        }

        
        let response = await AdminAPI.createImportedSamples(tableDataToSubmit)
        if(!response.success){
            let msg = "Unable to create Samples at this time.";
            if(response.reason){
                msg = response.reason
            }
            setShowLoading(false)
            return sweetalert.fire({icon: 'error', title: "", text: msg});
        }

        sweetalert.fire({icon: 'success', title: '', text: 'Samples created'})
        .then(()=>{
            setSubmitButtonClicked(true)
            setShowLoading(false)
            setTableData(response.createdSamples)
        });

        setShowLoading(false)
    } 

    const clearTable = () => {
        setTableData(null)
        setSubmitButtonClicked(false)
        setImportedCSVFacility(null)
    }


    const handleCellChange = (e, rowIndex, columnName, fieldTypeID) => {
        const updatedData = [...tableData];
        
        if(fieldTypeID === CustomFields.DATE_FIELD){
            updatedData[rowIndex][columnName] = !e ? '' : e;
        }
        else if(fieldTypeID === CustomFields.SELECT_FIELD){
            updatedData[rowIndex][columnName] = !e ? null : Array.isArray(e) ? e : e.value;
        }
        else{
            updatedData[rowIndex][columnName] = !e ? '' : e.target.value;
        }
        setTableData(updatedData);
    };


    const validate = (rowIndex, header, customField) => {
        let fieldTypeID = customField && customField.FieldTypeID ? customField.FieldTypeID : null;

        //required validation
        if(fieldTypeID === CustomFields.DATE_FIELD){
            if(customField.Options.isRequired){
                if(!tableData[rowIndex][header] && tableData[rowIndex][header] !== 0){
                    setShowLoading(false);
                    sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> must be a valid date</p>`})
                    return false
                }                
            }
        }
        else if(fieldTypeID === CustomFields.SELECT_FIELD){
            if(customField.Options.isRequired){
                if(!tableData[rowIndex][header]){
                    setShowLoading(false);
                    sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> cannot be blank</p>`})
                    return false
                }
            }

            let splitStringArray;
            let tempArray = [];
            //check if array. if not, convert to array.
            if(!Array.isArray(tableData[rowIndex][header]) && !Number(tableData[rowIndex][header]) && header != 'Tests'){
                splitStringArray = tableData[rowIndex][header].split(",");
                                    
                for (let i = 0; i < splitStringArray.length; i++) {
                    //verify each element value is in options
                    if(customField && customField.Options && customField.Options.options){
                        const objFound = customField.Options.options.filter(obj => obj.value == splitStringArray[i].trim());
                        if(objFound && objFound.length > 0){
                            tempArray.push({label: splitStringArray[i], value: splitStringArray[i]})
                            continue;
                        }
                        else{
                            sweetalert.fire({icon: "error", title: '', html: `<p><b>Row ${rowIndex + 1} - ${customField.DisplayName}: <i>${splitStringArray[i]}</i></b> is not valid. Please select valid options from the dropdown.</p>`})
                            setShowLoading(false)
                            return;
                        }
                    }
                    else{
                        sweetalert.fire({icon: "error", title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> has no options.</p>`})
                        setShowLoading(false)
                        return false;
                    }
                }
                tableData[rowIndex][header] = tempArray;
            }
            else if(Array.isArray(tableData[rowIndex][header]) && header != 'Tests'){
                for (let i = 0; i < tableData[rowIndex][header].length; i++) {
                    if(customField && customField.Options && customField.Options.options){
                        const objFound = customField.Options.options.filter(obj => obj.value == tableData[rowIndex][header][i].value.trim());
                        if(objFound && objFound.length > 0){
                            continue;
                        }
                        else{
                            sweetalert.fire({icon: "error", title: '', html: `<p><b>Row ${rowIndex + 1} - ${customField.DisplayName}: <i>${tableData[rowIndex][header][i].value}</i></b> is not valid. Please select valid options from the dropdown.</p>`})
                            setShowLoading(false)
                            return;
                        }                  
                    }
                    else{
                        sweetalert.fire({icon: "error", title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> has no options.</p>`})
                        setShowLoading(false)
                        return false;
                    }
                }
            }
        }
        else if(fieldTypeID === CustomFields.NUMBER_FIELD){
            if(customField.Options.isRequired){
                if(!tableData[rowIndex][header] && Number(tableData[rowIndex][header]) !== 0){
                    setShowLoading(false);
                    sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> cannot be blank</p>`}) 
                    return false
                }
                else if(String(tableData[rowIndex][header]).length < 1){
                    setShowLoading(false);
                    sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> cannot be blank</p>`}) 
                    return false
                }
            }
        }
        else{
            if(customField.Options.isRequired){
                if(!tableData[rowIndex][header] || tableData[rowIndex][header].trim().length < 1){
                    setShowLoading(false);
                    sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> cannot be blank</p>`})
                    return false
                }
            }
        }

        //max-length validation
        if((fieldTypeID === CustomFields.TEXT_FIELD || fieldTypeID === CustomFields.TEXTAREA_FIELD) && tableData[rowIndex][header] && tableData[rowIndex][header].length > 0 &&  customField.Options.maxLength){
            if(!tableData[rowIndex][header] || tableData[rowIndex][header].length > customField.Options.maxLength){
                setShowLoading(false);
                sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> cannot be longer than ${customField.Options.maxLength} characters</p>`})
                return false
            }
        }

        //email format validation
        if(fieldTypeID === CustomFields.EMAIL_FIELD && tableData[rowIndex][header] && tableData[rowIndex][header].length > 0){
            if(!tableData[rowIndex][header] || !isValidEmail(tableData[rowIndex][header])){
                setShowLoading(false);
                sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> must be a valid email</p>`})
                return false
            }
        }
        
        //phone format validation
        if(fieldTypeID === CustomFields.PHONE_FIELD && tableData[rowIndex][header] && tableData[rowIndex][header].length > 0){
            let phoneNumber = `+1${tableData[rowIndex][header]}`;
            if(!tableData[rowIndex][header] || !isPossiblePhoneNumber(phoneNumber)){
                setShowLoading(false);
                sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> must be a valid phone number</p>`})
                return false
            }
        }

        //is numeric and within range
        if(fieldTypeID === CustomFields.NUMBER_FIELD && (tableData[rowIndex][header] || tableData[rowIndex][header] == 0)){
            let number = Number(tableData[rowIndex][header]);
            if(isNaN(number)){
                setShowLoading(false);
                sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> must be a valid number</p>`})
                return false
            }
            if(number < customField.Options.min || number > customField.Options.max ){
                setShowLoading(false);
                sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> must be within range of <b>${customField.Options.min}</b> and <b>${customField.Options.max}</b></p>`})
                return false
            }
        }

        //allow future date
        
        if(fieldTypeID === CustomFields.DATE_FIELD && tableData[rowIndex][header]){
            if(!customField.Options.allowFutureDate && new Date(tableData[rowIndex][header]) > new Date()){                
                setShowLoading(false);
                sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${rowIndex + 1}: ${customField.DisplayName}</b> cannot be a future date</p>`})
                return false
            }
        }

        return true;
    }


    const getTableHeaders = () => {
        let keys = Object.keys(tableData[0])
        let jsx = [];
        if(tableData[0].ReqNum){
            for (let i = 0; i < keys.length; i++) {
                jsx.push(<th key={i} style={{width: "17rem", position: "sticky", top: 0, backgroundColor: '#fff'}}>{keys[i]}</th>)
            }
        }
        else{
            jsx.push(<th style={{width: "4rem", position: "sticky", top: 0, backgroundColor: '#fff'}}>#</th>)
            for (let i = 0; i < keys.length; i++) {
                if(keys[i] === 'ID' || keys[i] === 'ReqNum'){
                    continue
                }
                jsx.push(<th key={i} style={{width: "17rem", position: "sticky", top: 0, backgroundColor: '#fff'}}>{keys[i]}</th>)
            }
        }

        return jsx;
    }

    const getTableRows = () => {

        let serviceFields = [
            {
                DisplayName: 'Tests',
                FieldTypeID: CustomFields.SELECT_FIELD,
                Options: {
                    isMulti: importedCSVService ? importedCSVService.AllowMultipleTests : false,
                    options: importedCSVTests && importedCSVTests.length > 0 ? importedCSVTests.map(it => {return {label: it.Name, value: it.ID}}) : [],
                    isRequired: true
                }
            },
            {
                DisplayName: 'Specimen ID',
                FieldTypeID: CustomFields.TEXT_FIELD,
                Options: {
                    isRequired: importedCSVService && importedCSVService.IsLabService ? true : false,
                    maxLength: 50,
                }
            },
            {
                DisplayName: 'Specimen Source',
                FieldTypeID: CustomFields.SELECT_FIELD,
                Options: {
                    isMulti: false,
                    options: importedCSVSpecimenSources && importedCSVSpecimenSources.length > 0 ? importedCSVSpecimenSources : [],
                    isRequired: true
                },
            },
            {
                DisplayName: 'Lot',
                FieldTypeID: CustomFields.TEXT_FIELD,
                Options: {
                    isRequired: false,
                    maxLength: 50,
                }
            },
            {
                DisplayName: 'Memo',
                FieldTypeID: CustomFields.TEXTAREA_FIELD,
                Options: {
                    isRequired: false,
                    maxLength: null,
                }
            },
            {
                DisplayName: 'Collection Date',
                FieldTypeID: CustomFields.DATE_FIELD,
                Options: {
                    isRequired: true,
                }
            },
            {
                DisplayName: 'Test Ordered Date',
                FieldTypeID: CustomFields.DATE_FIELD,
                Options: {
                    isRequired: true,
                }
            },
        ]

        let tableFields = allCustomQuestions && allCustomQuestions.length > 0 ? allCustomQuestions : [];
        let combinedCustomFields = tableFields.concat(serviceFields);

        let jsx = [];
        for (let i = 0; i < tableData.length; i++) {
            let row = [];
            let keys = Object.keys(tableData[i])
            row.push(<td className="pt-3">{i + 1}</td>)
            for (let j = 0; j < keys.length; j++) {
                if(!combinedCustomFields || combinedCustomFields.length < 1){
                    sweetalert.fire({icon: 'error', title: '', text: 'CSV Column headers not found. Re-check CSV column headers.'})
                }
                for (let k = 0; k < combinedCustomFields.length; k++) {
                    if(combinedCustomFields[k].DisplayName === keys[j]){
                        if(combinedCustomFields[k].FieldTypeID === CustomFields.TEXT_FIELD){
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}>                     
                                    <input
                                        className="form-control"
                                        autoComplete={"off"}
                                        type="text"
                                        value={tableData[i][keys[j]]}
                                        onBlur={(e) => validate(i, keys[j], combinedCustomFields[k])}
                                        onChange={(e) => handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)}
                                    />
                                </td>
                            )
                        }
                        else if(combinedCustomFields[k].FieldTypeID === CustomFields.EMAIL_FIELD){
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}>                     
                                    <input
                                        className="form-control"
                                        autoComplete={"off"}
                                        type="email"
                                        value={tableData[i][keys[j]]}
                                        onBlur={(e) => validate(i, keys[j], combinedCustomFields[k])}
                                        onChange={(e) => handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)}
                                    />
                                </td>
                            )
                        }
                        else if(combinedCustomFields[k].FieldTypeID === CustomFields.TEXTAREA_FIELD){
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}>   
                                    <textarea
                                        maxLength={combinedCustomFields[k].Options.maxLength}
                                        rows={1}
                                        className={'form-control'}
                                        autoComplete={"off"}
                                        value={tableData[i][keys[j]]}
                                        onBlur={(e) => validate(i, keys[j], combinedCustomFields[k])}
                                        onChange={(e) => handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)}
                                    />                 
                                </td>
                            )
                        }
                        else if(combinedCustomFields[k].FieldTypeID === CustomFields.DATE_FIELD){
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}>  
                                    <DatePicker 
                                        disableCalendar={true}
                                        className="form-control" 
                                        onChange={(e) => {
                                            handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)
                                        }}
                                        value={tableData[i][keys[j]] ? new Date(tableData[i][keys[j]]) : null} 
                                    />                 
                                </td>
                            )
                        }
                        else if(combinedCustomFields[k].FieldTypeID === CustomFields.NUMBER_FIELD){
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}>   
                                    <input
                                        min={combinedCustomFields[k].Options.min}
                                        max={combinedCustomFields[k].Options.max}
                                        className="form-control"
                                        autoComplete={"off"}
                                        type="number"
                                        value={tableData[i][keys[j]]}
                                        onBlur={(e) => validate(i, keys[j], combinedCustomFields[k])}
                                        onChange={(e) => handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)}
                                    />              
                                </td>
                            )
                        }
                        else if(combinedCustomFields[k].FieldTypeID === CustomFields.PHONE_FIELD){
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}>     
                                    <input
                                        className="form-control"
                                        autoComplete={"off"}
                                        type="tel"
                                        value={tableData[i][keys[j]]}
                                        onBlur={(e) => validate(i, keys[j], combinedCustomFields[k])}
                                        onChange={(e) => handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)}
                                    />         
                                </td>
                            )
                        }
                        else if(combinedCustomFields[k].FieldTypeID === CustomFields.SELECT_FIELD){
                            let valueObj;
                            if(!tableData[i][keys[j]] || tableData[i][keys[j]].length < 1){
                                valueObj = null;
                            }
                            else if(Array.isArray(tableData[i][keys[j]])){
                                valueObj = tableData[i][keys[j]];
                            }
                            else if(combinedCustomFields[k].DisplayName != 'Specimen Source' && !Number(tableData[i][keys[j]]) && tableData[i][keys[j]].includes(',')){
                                let splitStringArray = tableData[i][keys[j]].split(",");                                
                                if(!combinedCustomFields[k].Options.isMulti){
                                    if(!splitStringArray || splitStringArray.length > 1){
                                        return sweetalert.fire({icon: 'error', title: '', html: `<p><b>Row ${i + 1}: ${combinedCustomFields[k].DisplayName}</b> cannot have multiple selections</p>`})
                                    }
                                    else{
                                        valueObj = {label: tableData[i][keys[j]].trim(), value: tableData[i][keys[j]].trim()}
                                    }
                                }
                                else{
                                    if(splitStringArray && splitStringArray.length > 0){
                                        valueObj = [];
                                        if(combinedCustomFields[k].DisplayName == 'Tests'){
                                            for (let i = 0; i < combinedCustomFields[k].Options.options.length; i++) {
                                                for (let j = 0; j < splitStringArray.length; j++) {
                                                    if(combinedCustomFields[k].Options.options[i].label === splitStringArray[j].trim()){
                                                        valueObj.push(combinedCustomFields[k].Options.options[i])
                                                        break;
                                                    }                                                    
                                                }                                                
                                            }
                                        }
                                        else{
                                            splitStringArray.map(a => {
                                                valueObj.push({label: a.trim(), value: a.trim()});
                                            })
                                        }
                                    }
                                    else{
                                        valueObj = null;
                                    }
                                }
                            }
                            else{
                                if(combinedCustomFields[k].DisplayName == 'Specimen Source' && Number(tableData[i][keys[j]])){
                                    valueObj = {label: importedCSVSpecimenSources.find(f => f.value === tableData[i][keys[j]]).label, value: tableData[i][keys[j]]}
                                }
                                else if(combinedCustomFields[k].DisplayName == 'Tests' && Number(tableData[i][keys[j]])){
                                    valueObj = {label: importedCSVTests.find(f => f.ID === tableData[i][keys[j]]).Name, value: tableData[i][keys[j]]}
                                }
                                else{
                                    valueObj = {label: tableData[i][keys[j]], value: tableData[i][keys[j]]}
                                }
                            }    
                            
                            row.push(
                                <td key={keys[j]} className="TableDataWrap" style={{width: '17rem'}}> 
                                    <Select
                                        menuPlacement={i > 3 && i > (tableData.length - 7) ? "top" : "bottom"}
                                        isMulti={combinedCustomFields[k].Options.isMulti}
                                        isSearchable={true}
                                        isClearable={combinedCustomFields[k].Options.isMulti ? false : !combinedCustomFields[k].Options.isMulti && combinedCustomFields[k].Options.isRequired ? false :  true}
                                        placeholder={"Please select..."}
                                        className={"state_select"}
                                        options={combinedCustomFields[k].Options.options}
                                        value={valueObj}
                                        onBlur={(e) => validate(i, keys[j], combinedCustomFields[k])}
                                        onChange={ (e)=> handleCellChange(e, i, keys[j], combinedCustomFields[k].FieldTypeID)}
                                    />              
                                </td>
                            )
                        }
                    }                   
                }  
            }
            jsx.push(<tr key={i}>{row}</tr>)
        }
        return jsx;
    }

    const generateExcelDoc = () => {
        setShowLoading(true)
        if(!selectedFacility || !selectedService){
            setShowLoading(false);
            return sweetalert.fire({icon: 'error', title:"", text: "Facility and Service must be selected"})
        }
        SamplesAPI.generateExcelDocBasedOnService(selectedService.value)
            .then(blobUrl => {
                if(typeof blobUrl === 'string'){
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = `${selectedService.label} Service.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(blobUrl);
                }
                else{
                    console.error('Error from server:', blobUrl);
                    return sweetalert.fire({icon: 'error', title: '', text: 'Error when downloading excel doc. Please try again.'})
                }
        })
        .catch(error => {
            console.error('Error downloading the file:', error);
            return sweetalert.fire({icon: 'error', title: '', text: 'Error when downloading excel doc. Please try again.'})
        })
        .finally(() => {
            setShowLoading(false);
        });


    }

    const getFacilityServices = () => {
        let facility = facilities && facilities.length > 0 && selectedFacility && selectedFacility.value ? facilities.find(f => f.ID === selectedFacility.value) : null
        let facilityServiceIDs = facility && facility.ServiceIDs ? JSON.parse(facility.ServiceIDs) : null;
        let availableServicesLabels = facilityServiceIDs  ? 
            services.filter(s => facilityServiceIDs.includes(s.ID)) :
            [];
        return availableServicesLabels.map(s => {return {label:s.Name, value:s.ID}});
    }

    return (
        <div className="container-fluid">
            <Overlay show_loading={showLoading}/>
            <div className={"row"}>
                <div className="col-12 col-lg-6 pt-2">
                    <div className="card mb-2">
                        <div className="card-header verlag-bold">
                            <h4>Template Download</h4>
                        </div>
                        <div className="card-body"> 
                            <div className="form-group row">
                                <label htmlFor="Facility" className="col-sm-4 col-form-label" style={{fontWeight: 'bold'}}>Facility</label>
                                <div className="col-sm-8  p-0 m-0">
                                    <Select
                                        id={"Service"}
                                        isSearchable={ true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={()=>  "No option"}
                                        className={"state_select"}
                                        options={facilities.map(s => {return {label:s.FacilityName, value:s.ID}})}
                                        onChange={(e) => {
                                            setSelectedFacility(e)
                                            setSelectedService(null)
                                        }}
                                        value={selectedFacility}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="Service" className="col-sm-4 col-form-label" style={{fontWeight: 'bold'}}>Service</label>
                                <div className="col-sm-8  p-0 m-0">
                                    <Select
                                        id={"Service"}
                                        isDisabled={selectedFacility ? false : true}
                                        isSearchable={ true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={()=>  "No option"}
                                        className={"state_select"}
                                        options={getFacilityServices()}
                                        onChange={(e) => setSelectedService(e)}
                                        value={selectedService}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button className={"btn immySubmitButtonOutline"}
                                onClick={ () => generateExcelDoc()}
                            >Download</button>
                            <button className="btn immyClearButtonOutline float-right"
                                onClick={()=> {
                                setSelectedFacility(null);
                                setSelectedService(null)
                                }}
                            >Clear</button>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 pt-2">
                    <div className="card mb-2">
                        <div className="card-header verlag-bold">
                            <h4>File Upload
                                <span className="float-right text-danger">*file must be saved as .csv</span>
                            </h4>
                        </div>
                        <div className="card-body"> 
                            <CSVReader
                                onUploadRejected ={(e) => {
                                    return sweetalert.fire({icon: 'error', title: '', text: 'File must be saved as a .csv'})
                                }} 
                                onDragOver={(event: DragEvent) => {
                                    event.preventDefault();
                                    setZoneHover(true);
                                  }}
                                onDragLeave={(event: DragEvent) => {
                                event.preventDefault();
                                setZoneHover(false);
                                }}
                                onUploadAccepted={(results: any) => {
                                    setShowLoading(true)
                                    //if results.data.length is less than 10 then it is just headers
                                    if(!results || !results.data || results.data.length < 10){
                                        setShowLoading(false)
                                        return sweetalert.fire({icon: 'error', title: '', text: 'No Samples found on csv'})
                                    }

                                    if(results && results.data && results.data.length > 91){
                                        setShowLoading(false)
                                        return sweetalert.fire({icon: 'error', title: '', text: 'Please limit CSV to 80 rows'})
                                    }

                                    let service = results.data[0][5] ? JSON.parse(results.data[0][5]) : null;
                                    if(!service){
                                        setShowLoading(false)
                                        return sweetalert.fire({icon: 'error', title: '', text: `CSV is corrupt. This is likely due to changes made to CSV directly. All changes need to be made to Excel document and then saved as CSV.`})
                                    }
                                    setImportedCSVService(service);
                                    let tests = results.data[1][5] ? JSON.parse(results.data[1][5]) : null;
                                    if(!tests || tests.length < 1){
                                        setShowLoading(false)
                                        return sweetalert.fire({icon: 'error', title: '', text: `CSV is corrupt. This is likely due to changes made to CSV directly. All changes need to be made to Excel document and then saved as CSV.`})
                                    }
                                    setImportedCSVTests(tests)
                                    let specimenSources = results.data[2][5] ? JSON.parse(results.data[2][5]) : null;
                                    if(!specimenSources || specimenSources.length < 1){
                                        setShowLoading(false)
                                        return sweetalert.fire({icon: 'error', title: '', text: `CSV is corrupt. This is likely due to changes made to CSV directly. All changes need to be made to Excel document and then saved as CSV.`})
                                    }
                                    setImportedCSVSpecimenSources(specimenSources)

                                    let headers = results.data[9];
                                    let tempArray = results.data.slice(10);
                                    
                                    //check for empty arrays
                                    let nonEmptyArray = tempArray.filter(innerArr => !innerArr.every(element => element === ""));                                    

                                    //get total number of columns from combined 
                                    let serviceSpecificCustomQuestions = []; 
                                    for (let i = 0; i < allCustomQuestions.length; i++) {
                                        if(allCustomQuestions[i].ServiceID){
                                            if(allCustomQuestions[i].ServiceID === service.ID){
                                                serviceSpecificCustomQuestions.push(allCustomQuestions[i])
                                            }
                                        }
                                        else{
                                            serviceSpecificCustomQuestions.push(allCustomQuestions[i])
                                        }
                                        
                                    }

                                    if(serviceSpecificCustomQuestions && serviceSpecificCustomQuestions.length > 0 && headers && headers.length > 0 && serviceSpecificCustomQuestions.length + 7 !== headers.length){
                                        setShowLoading(false)
                                        return sweetalert.fire({icon: 'error', title: '', text: `Something went wrong. Make sure you are using the latest Excel document.`})
                                    }

                                    setAllCustomQuestions(serviceSpecificCustomQuestions)
                                    
                                    const keys = headers;
                                    const objectsArray = nonEmptyArray.map((item, index) => {
                                        let obj = {};
                                        keys.forEach((key, index) => {
                                            obj[key] = item[index];
                                        });
                                        return obj;
                                    })
                                    setTableData(objectsArray)
                                    setShowLoading(false)
                                }}
                                >
                                {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                    Remove,
                                }: any) => (
                                    <>
                                    <div
                                      {...getRootProps()}
                                      style={Object.assign(
                                        {},
                                        styles.zone,
                                        zoneHover && styles.zoneHover
                                      )}
                                    >
                                      {acceptedFile ? (
                                        <>
                                          <div style={styles.file}>
                                            <div style={styles.info}>
                                              <span style={styles.size}>
                                                {formatFileSize(acceptedFile.size)}
                                              </span>
                                              <span style={styles.name}>{acceptedFile.name}</span>
                                            </div>
                                            <div style={styles.progressBar}>
                                              <ProgressBar />
                                            </div>
                                            <div
                                              {...(() => {
                                                const originalRemoveProps = getRemoveFileProps();
                                                const originalOnClick = originalRemoveProps.onClick;
            
                                                return {
                                                    ...originalRemoveProps,
                                                    onClick: (event) => {
                                                        clearTable()
                                                        if (originalOnClick) {
                                                            originalOnClick(event);
                                                        }
                                                    }
                                                };
                                            })()}
                                              style={styles.remove}
                                            >
                                              <Remove color={removeHoverColor} />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        'Drop CSV file here or click to upload'
                                      )}
                                    </div>
                                  </>
                                )}
                            </CSVReader>
                            <div className="form-group row" style={{marginRight: '5px'}}>
                                <label htmlFor="ImportedFacility" className="col-sm-4 col-form-label" style={{fontWeight: 'bold'}}>Importing Facility</label>
                                <div className="col-sm-8 p-0 m-0" style={{zIndex: 30}}>
                                    <Select
                                        id={"ImportingFacility"}
                                        isSearchable={ true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={()=>  "No option"}
                                        className={"state_select"}
                                        options={facilities.map(s => {return {label:s.FacilityName, value:s.ID}})}
                                        onChange={(e) => {
                                            setImportedCSVFacility(e)
                                        }}
                                        value={importedCSVFacility}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




             {tableData && tableData.length > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop" style={{zIndex: 5}}>
                    <h4 className="text-center text-md-left">
                      Rows
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"}>
                          Total: {tableData.length}
                        </h4>
                      </section>


                    {tableData && tableData.length > 0 && tableData[0].ReqNum && 
                      <button
                        className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                        onClick={() => handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    }
                    </h4>
                  </div>


                  <div className="card-body p-0 m-0 table-responsive"  style={tableData && tableData.length > 0 && tableData[0].ReqNum ? {} : {overflowY: "auto", maxHeight: '800px' }}> 
                  {tableData && tableData.length > 0 && tableData[0].ReqNum ? 
                    <SimpleTable
                        columns={[
                        { label: "Confirmation #", key: "ReqNum", popoverText: "Click to sort by Confirmation Number"},
                        { label: "Patient UID", key: "PatientPortalUID"},
                        { label: "First Name", key: "PatientFirstName"},
                        { label: "Last Name", key: "PatientLastName"},
                        { label: "Date of Birth", key: "PatientDOB", formatFunc: parseDate},
                        ]}
                        table_data={tableData} table_style="tableFixHead"
                    />
                    :    
                    <table className="table p-0 m-0" style={{tableLayout: "fixed"}}>
                        <thead style={{position: "sticky", zIndex: 20}}>
                            <tr>
                                {getTableHeaders()}
                            </tr>
                        </thead>
                        <tbody>
                            {getTableRows()}
                        </tbody>
                    </table> 
                    }
                  </div>
                  <div className="card-footer">   
                    {submitButtonClicked ? <div className="d-none"></div> :    
                    <button
                    className={"btn immySubmitButtonOutline float-right mr-5"}
                    onClick={() => {
                        validateTable()
                    }}
                    >
                    Submit
                    </button>
                    }
                </div>
                </div>
              </div>
            )}


            </div>
        </div>
    );
}