import React from "react";
import ModalForm from "./ModalForm";
import {InsuranceImageZoomModal} from "./InsuranceImageZoomModal";
import {hideModal, showModalNoOutsideClick} from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";



interface SampleInsuranceCardProps{
    frontImage: string;
    backImage: string;
    reqNum?:string;
    handleLoading?;
    closeInBetween?: () => void
}

interface SampleInsuranceCardState{
    clickedImage: string
    showInBetween:boolean
}

export class SampleInsuranceCardModal extends React.Component<SampleInsuranceCardProps, SampleInsuranceCardState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state = {clickedImage:'', showInBetween: false,};
        this.formRef = React.createRef()
    }

    downloadInsuranceCard = (url, side) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.props.reqNum ? this.props.reqNum : ''} - Insurance Card Image - ${side}`;
        link.click();
    }


    public static readonly ID = "SampleInsuranceCard"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100007} />
                <InsuranceImageZoomModal image={this.state.clickedImage} handleLoading={() => this.setState({showInBetween: false})}/>
                <div className="modal fade form_modal" id={SampleInsuranceCardModal.ID} tabIndex={-1} style={{zIndex: 100006}} role="dialog"
                     aria-label="Insurance Cards"
                     aria-hidden="true"
                     data-backdrop="static" data-keyboard="false"
                >
                    <div className="modal-dialog modal-xl " role="document" >
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid  min-vh-10">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2 text-center">
                                                <div className="card-header verlag-bold">
                                                    <h4 style={{fontWeight: 'bold'}}>Insurance Cards
                                                    <button  style={{outline: 'none'}} type="button" className="close pr-4" data-dismiss="modal"
                                                            aria-label="Close" onClick={() => {this.props.closeInBetween(); this.props.handleLoading()}}>
                                                        <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                    </button>
                                                    </h4>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className="form-group row">
                                                        <div className={"col-12"}>
                                                            <label htmlFor={"FrontInsuranceImage"} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Front</label>
                                                        </div>
                                                        <button className={"col-12 p-0 m-0"} onClick={() => {this.setState({clickedImage: this.props.frontImage, showInBetween: true});showModalNoOutsideClick(InsuranceImageZoomModal.ID)}}>
                                                            <img id={"FrontInsuranceImage"} width={500} style={{cursor: "pointer"}} src={this.props.frontImage} alt={`${this.props.reqNum ? this.props.reqNum : ''} - Insurance Card - Front`}/>
                                                        </button>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className={"col-12"}>
                                                            <label htmlFor={"BackInsuranceImage"} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Back</label>
                                                        </div>
                                                        <button className={"col-12 p-0 m-0"} onClick={() => {this.setState({clickedImage: this.props.backImage, showInBetween: true}); showModalNoOutsideClick(InsuranceImageZoomModal.ID)}}>
                                                            <img id={"BackInsuranceImage"} width={500} style={{cursor: "pointer"}} src={this.props.backImage} alt={`${this.props.reqNum ? this.props.reqNum : ''} - Insurance Card - Back`}/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button className={"btn btn-success float-left"} onClick={() => {this.downloadInsuranceCard(this.props.frontImage, 'Front'); this.downloadInsuranceCard(this.props.backImage, 'Back'); this.props.closeInBetween(); hideModal(SampleInsuranceCardModal.ID); this.props.handleLoading()}}>
                                                        Download
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

