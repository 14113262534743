import {formatPhone, parseDate} from "../../util/FormatUtil";
import React from "react";
import {TestReport, TestReportAddtlInfo} from "../../types/TestReport";
import { Chart as ChartJS, CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip, Legend } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getBarChart } from "./helpers/barCharts";
import PathogenDescriptions from "./childComponents/PathogenDescriptions";
import { checkThreshold, dateFormatter, getAbbreviations, getAllergies, getBarBackgroundColor, getRxRecommendation } from "./helpers/utils";


ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    ChartDataLabels
  );



interface PeriodontalProps{
    result?:TestReportAddtlInfo
    test
    services?
    allResults?
    branding?
}
interface PeriodontalState{
    
}

export default class Periodontal extends React.Component<PeriodontalProps, PeriodontalState>{

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(): void {
        document.body.style.backgroundImage = ``;
        document.body.style.backgroundColor = '#FFFFFF'
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        
        //allergy and rx recommendation section
        let tests = this.props && this.props.result && this.props.result.Tests ? this.props.result.Tests : null;
        let testsAndResultsMap = this.props && this.props.result && this.props.result.TestsAndResultsMap ? this.props.result.TestsAndResultsMap : null;
        let customData = this.props && this.props.result && this.props.result.CustomFieldData ? this.props.result.CustomFieldData : null;
        let rxRecommendation;
        let testMeetsOrExceedsArray = checkThreshold(tests, testsAndResultsMap);
        if(testMeetsOrExceedsArray && testMeetsOrExceedsArray.length > 0){
            let allergies = getAllergies(customData);
            rxRecommendation = getRxRecommendation(testMeetsOrExceedsArray, allergies);
        }

        // get current test date, current test result id's, and current test result values
        let formattedCurrentTestDate = this.props.result && this.props.result.TestResultDate ? dateFormatter(new Date(this.props.result.TestResultDate)) : null;

        var currentTestResultValues = this.props.result.TestsAndResultsMap.map((d) => { return Number(d.ResultValue) });

        //get first and second prior test dates and info
        let firstPriorTestDate;
        let secondPriorTestDate;
        if(this.props.result && this.props.result.otherSamples && this.props.result.otherSamples.length > 0){
            firstPriorTestDate = this.props.result.otherSamples[0].TestResultDate ? dateFormatter(new Date(this.props.result.otherSamples[0].TestResultDate)) : null;

            var firstPriorTestResultValues = this.props.result.otherSamples[0] ? JSON.parse(this.props.result.otherSamples[0].TestsAndResultsMap).map((d) => { return Number(d.ResultValue) }) : null;

            secondPriorTestDate = this.props.result.otherSamples[1] && this.props.result.otherSamples[1].TestResultDate ? dateFormatter(new Date(this.props.result.otherSamples[1].TestResultDate)) : null;

            var secondPriorTestResultValues = this.props.result.otherSamples[1] ? JSON.parse(this.props.result.otherSamples[1].TestsAndResultsMap).map((d) => { return Number(d.ResultValue) }) : null;

        }


        const getDatasets = () => {
            let currentProps = this.props;
            let firstPriorProps = this.props.result.otherSamples && this.props.result.otherSamples.length > 0 ? this.props.result.otherSamples[0] : null;
            let secondPriorProps = this.props.result.otherSamples && this.props.result.otherSamples.length > 0 ? this.props.result.otherSamples[1] : null;
            let allResults = this.props.allResults;
            if(this.props.result.otherSamples && this.props.result.otherSamples.length > 0){
                if(this.props.result.otherSamples.length === 2){
                    return [
                        {
                            label: secondPriorTestDate,
                            data: secondPriorTestResultValues,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, secondPriorProps, allResults)},
                            borderRadius: 10,
                            barPercentage: .5,
                            date: secondPriorTestDate
                        },
                        {
                            label: firstPriorTestDate,
                            data: firstPriorTestResultValues,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, firstPriorProps, allResults)},
                            borderRadius: 10,
                            barPercentage: .5,
                            date: firstPriorTestDate
                        },
                        {
                            label: formattedCurrentTestDate,
                            data: currentTestResultValues,
                            barPercentage: .85,
                            date: formattedCurrentTestDate,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, currentProps, allResults)},
                            borderRadius: 10,
                        },   
                    ]
                }
                else if(this.props.result.otherSamples.length === 1){
                    return [
                        {
                            label: firstPriorTestDate,
                            data: firstPriorTestResultValues,
                            backgroundColor: function(context){ return getBarBackgroundColor(context, firstPriorProps, allResults)},
                            borderRadius: 10,
                            barPercentage: .5,
                            date: firstPriorTestDate,
                        },
                        {
                            label: formattedCurrentTestDate,
                            data: currentTestResultValues,
                            barPercentage: .85,
                            date: formattedCurrentTestDate,
                            backgroundColor: function(context){return getBarBackgroundColor(context, currentProps, allResults)},
                            borderRadius: 10,
                        },   
                    ]
                }
            }
            else{
                return [{
                    label: formattedCurrentTestDate,
                    data: currentTestResultValues,
                    barPercentage: .85,
                    date: formattedCurrentTestDate,
                    backgroundColor: function(context){ return getBarBackgroundColor(context, currentProps, allResults)},
                    borderRadius: 10,
                }]
            }
        }       

        //CURRENT 
        let labels = this.props.result && this.props.result.Tests ? getAbbreviations(this.props.result.Tests) : [];
        let oralBacteriaProfile =  {
            labels,
            datasets: getDatasets() as any           
        }

        const address = this.props.result.ReportAddress;
        const firstCommaIndex = address.indexOf(',')

        let firstLine = address.slice(0, firstCommaIndex);
        let secondLine = address.slice(firstCommaIndex + 2);

        return(
            <div className="col-12 border printPerioWrapper">
                <div className="col-12 text-center mb-3">
                        <img src={this.props.branding.ProductAltLogoURL}  height={90} className="d-inline-block align-top nav-href pr-sm-2 pr-0" alt="Logo"/>
                </div>

                {/* print only  */}
                <div className="d-none d-print-flex pt-3 pb-3" style={{border: '3px solid', borderColor: this.props.branding.ProductButtonColorHexValue, borderRadius: '5px'}}>
                    <div className="col-4 p-2 " style={{borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-5 text-right">
                                <div>Date of Birth:</div>
                                <div>Gender:</div>
                                <div>Confirmation:</div>
                                <div>Patient Name:</div>
                            </div>
                            <div className="col-7 text-left font-weight-bold pl-0">
                                <div className={this.props && this.props.result && this.props.result.PatientDOB ? "" : "h-25"}>{this.props && this.props.result && this.props.result.PatientDOB ? parseDate(this.props.result.PatientDOB) : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.PatientGender ? "" : "h-25"}>{this.props && this.props.result && this.props.result.PatientGender ? this.props.result.PatientGender : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.ReqNum ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ReqNum ? this.props.result.ReqNum : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.PatientFirstName && this.props.result.PatientLastName ? "" : "h-25"}>{this.props && this.props.result && this.props.result.PatientFirstName && this.props.result.PatientLastName ? this.props.result.PatientFirstName + ' ' + this.props.result.PatientLastName : " "}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2" style={{borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-3 text-right">
                                <div>Provider:</div>
                                <div>Phone:</div>
                                <div>Email:</div>
                                <div>Address:</div>
                            </div>
                            <div className="col-9 text-left font-weight-bold pl-0">
                                <div className={this.props && this.props.result && this.props.result.ProviderFirstName && this.props.result.ProviderLastName ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ProviderFirstName && this.props.result.ProviderLastName ? this.props.result.ProviderFirstName + " " + this.props.result.ProviderLastName : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.ReportPhone ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ReportPhone ? formatPhone(this.props.result.ReportPhone) : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.ReportHelpEmail ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ReportHelpEmail ? this.props.result.ReportHelpEmail : " "}</div>
                                <div>{firstLine}</div>
                                <div>{secondLine}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <div className="row">
                            <div className="col-5 text-right">
                                <p>
                                    <div>Specimen ID:</div>
                                    <div>Internal ID:</div>
                                    <div>Source:</div>
                                    <div>Service:</div>
                                </p>
                            </div>
                            <div className="col-7 text-left font-weight-bold pl-0">
                                <p className="mb-0">
                                    <div className={this.props && this.props.result && this.props.result.SpecimenID ? "" : "h-25"}>{this.props && this.props.result && this.props.result.SpecimenID ? this.props.result.SpecimenID : " "}</div>
                                    <div className={this.props && this.props.result && this.props.result.InternalSpecimenID ? "" : "h-25"}>{this.props && this.props.result && this.props.result.InternalSpecimenID ? this.props.result.InternalSpecimenID : " "}</div>
                                    <div className={this.props && this.props.result && this.props.result.SpecimenSource ? "" : "h-25"}>{this.props && this.props.result && this.props.result.SpecimenSource ?this.props.result.SpecimenSource : " "}</div>
                                    <div className={this.props && this.props.result && this.props.result.Service && this.props.result.Service.Name ? "" : "h-25"}>{this.props && this.props.result && this.props.result.Service && this.props.result.Service.Name ? this.props.result.Service.Name : " "}</div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* screen only  */}
                <div className="d-print-none d-lg-flex pt-lg-3 pb-lg-3" style={{border: '3px solid #82d2e6', borderRadius: '5px'}}>
                    <div className="col-12 col-lg-4 p-2" style={window.innerWidth < 992 ? {borderBottom: '1px solid black'
} : {borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-5 text-lg-right">
                                <div>Confirmation:</div>
                                <div>Date of Birth:</div>
                                <div>Gender:</div>
                                <div>Patient Name:</div>
                            </div>
                            <div className="col-7 text-right text-lg-left font-weight-bold pl-0">
                                <div className={this.props && this.props.result && this.props.result.ReqNum ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ReqNum ? this.props.result.ReqNum : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.PatientDOB ? "" : "h-25"}>{this.props && this.props.result && this.props.result.PatientDOB ? parseDate(this.props.result.PatientDOB) : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.PatientGender ? "" : "h-25"}>{this.props && this.props.result && this.props.result.PatientGender}</div>
                                <div className={this.props && this.props.result && this.props.result.PatientLastName && this.props.result.PatientLastName ? "" : "h-25"}>{this.props && this.props.result && this.props.result.PatientFirstName && this.props.result.PatientLastName ? this.props.result.PatientFirstName + ' ' + this.props.result.PatientLastName : " "}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2" style={window.innerWidth < 992 ? {borderBottom: '1px solid black'
} : {borderRight: '1px solid black'}}>
                        <div className="row">
                            <div className="col-3 text-lg-right">
                                <div>Provider:</div>
                                <div>Phone:</div>
                                <div>Email:</div>
                                <div>Address:</div>
                            </div>
                            <div className="col-9 text-right text-lg-left font-weight-bold pl-0">
                                <div className={this.props && this.props.result && this.props.result.ProviderFirstName && this.props.result.ProviderLastName ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ProviderFirstName && this.props.result.ProviderLastName ? this.props.result.ProviderFirstName + " " + this.props.result.ProviderLastName : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.ReportPhone ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ReportPhone ? formatPhone(this.props.result.ReportPhone) : " "}</div>
                                <div className={this.props && this.props.result && this.props.result.ReportHelpEmail ? "" : "h-25"}>{this.props && this.props.result && this.props.result.ReportHelpEmail ? this.props.result.ReportHelpEmail : " "}</div>
                                <div>{firstLine}</div>
                                <div>{secondLine}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2">
                        <div className="row">
                            <div className="col-5 text-lg-right">
                                <p>
                                    <div>Specimen ID:</div>
                                    <div>Internal ID:</div>
                                    <div>Source:</div>
                                    <div>Service:</div>
                                </p>
                            </div>
                            <div className="col-7 text-right text-lg-left font-weight-bold pl-0">
                                <p className="mb-0">
                                    <div className={this.props && this.props.result && this.props.result.SpecimenID ? "" : "h-25"}>{this.props && this.props.result && this.props.result.SpecimenID ? this.props.result.SpecimenID : " "}</div>
                                    <div className={this.props && this.props.result && this.props.result.InternalSpecimenID ? "" : "h-25"}>{this.props && this.props.result && this.props.result.InternalSpecimenID ? this.props.result.InternalSpecimenID : " "}</div>
                                    <div className={this.props && this.props.result && this.props.result.SpecimenSource ? "" : "h-25"}>{this.props && this.props.result && this.props.result.SpecimenSource ? this.props.result.SpecimenSource : " "}</div>
                                    <div className={this.props && this.props.result && this.props.result.Service && this.props.result.Service.Name ? "" : "h-25"}>{this.props && this.props.result && this.props.result.Service && this.props.result.Service.Name ? this.props.result.Service.Name : " "}</div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                <div className="row text-center p-2 my-3" style={{border: '1px solid lightgray', borderRadius: '0.25rem', marginLeft: '0.15rem', marginRight: '0.15rem'}}>
                    <p>
                        <h5>What is OraPath designed to do?</h5>
                        OraPath is engineered to detect five high-risk pathogens linked to the onset of Gingivitis, Periodontitis, Periimplantitis, and systemic inflammation. A high count of these bacteria can lead to implant failure, tooth loss, or bone loss, and may result in severe health issues if left untreated. By offering vital insights and suggestions, OraPath, when combined with antibiotics and treatments from your dentist, can help mitigate both oral and systemic risks. Thank you for entrusting OraPath with your salivary analysis and high-risk pathogen report.
                    </p>
                </div>

   
                <div className={window.innerWidth < 500 ? "d-none" : "card pt-2 centerChart printPerioWrapper removeBorder"}>
                    <div className="row">


                        {/* print only  */}
                        <div className="d-print-block d-none col-12 text-center">
                            <div className="card" style={{marginRight: '4.5rem', marginLeft: '4.5rem'}}>
                                <div className="row mt-2 mb-1">
                                    <div className="col-3">
                                        RESULTS REPORT  
                                    </div>
                                    <div className="col-6">
                                        <p style={{fontSize: "0.75rem"}}>Units = Log Genome Copies / ml</p>
                                    </div>
                                    <div className="col-3 pr-4">
                                        <div style={{backgroundImage:'linear-gradient(#33cc33, #009933)', borderRadius: '10px'}}>
                                            <span style={{color: 'aliceblue', fontWeight: "bold"}}>Low</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-3">
                                        FOR
                                    </div>
                                    <div className="col-6">
                                        <p style={{fontSize: "0.75rem"}}>*No colored bar represents no bacteria detected</p>
                                    </div>
                                    <div className="col-3 pr-4">
                                        <div style={{backgroundImage:'linear-gradient(#ffcc00, #ff9900)', borderRadius: '10px'}}>
                                           <span style={{color: 'aliceblue', fontWeight: "bold"}}>Moderate</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        {formattedCurrentTestDate}
                                    </div>
                                    <div className="col-6">
                                        <p style={{fontSize: "0.75rem"}}>*Thin grey line represents therapeutic threshold</p>
                                    </div>
                                    <div className="col-3 pr-4"> 
                                        <div style={{backgroundImage:'linear-gradient(#ff0000, #cc0000)', borderRadius: '10px'}}>
                                            <span style={{color: 'aliceblue', fontWeight: "bold"}}>High</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* screen only  */}
                        <div className="d-block d-print-none col-12 text-center">
                            <div className="card">
                                <div className="row mt-2 mb-1">
                                    <div className="col-3">
                                        RESULTS REPORT  
                                    </div>
                                    <div className="col-6">
                                        <p style={{fontSize: "0.75rem"}}>Units = Log Genome Copies / ml</p>
                                    </div>
                                    <div className="col-3 pr-4">
                                        <div style={{backgroundImage:'linear-gradient(#33cc33, #009933)', borderRadius: '10px'}}>
                                            <span style={{color: 'aliceblue', fontWeight: "bold"}}>Low</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-3">
                                        FOR
                                    </div>
                                    <div className="col-6">
                                        <p style={{fontSize: "0.75rem"}}>*No colored bar represents no bacteria detected</p>
                                    </div>
                                    <div className="col-3 pr-4">
                                        <div style={{backgroundImage:'linear-gradient(#ffcc00, #ff9900)', borderRadius: '10px'}}>
                                           <span style={{color: 'aliceblue', fontWeight: "bold"}}>Moderate</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        {formattedCurrentTestDate}
                                    </div>
                                    <div className="col-6">
                                        <p style={{fontSize: "0.75rem"}}>*Thin grey line represents therapeutic threshold</p>
                                    </div>
                                    <div className="col-3 pr-4"> 
                                        <div style={{backgroundImage:'linear-gradient(#ff0000, #cc0000)', borderRadius: '10px'}}>
                                            <span style={{color: 'aliceblue', fontWeight: "bold"}}>High</span>
                                        </div>
                                    </div>
                                </div>
                            </div>                  
                        </div>

                        <div className="col-12 text-center" style={{paddingRight: '6rem', paddingLeft: '5rem'}}>
                            {getBarChart(oralBacteriaProfile, 'Oral Bacteria Profile', this.props.result.Tests, this.props.branding.ProductButtonColorHexValue)}
                        </div>

                        
                    </div>
                </div>

                <div className={"row text-center p-2 mb-3"} style={{border: '1px solid lightgray', borderRadius: '0.25rem', marginLeft: '0.15rem', marginRight: '0.15rem'}}>
                    <div className="col-12">
                        <div className="pb-2"><b>Prescription recommendation(s): </b> {rxRecommendation ? rxRecommendation : 'None at this time'}</div>
                        <div className="small">
                            <i>These recommendations suggest using antibiotics in conjunction with scaling and root planing for effective results. Antibiotics alone aren't a complete treatment for periodontal disease. Before prescribing any antibiotics, it's essential to review the patient's medications thoroughly and check for antibiotic allergies and potential interactions.</i>  
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column col-sm-12 p-0" id="perioResultWrapper">
                    <div className="col-12 pl-0">
                        <div className="col-12 text-center ml-0 mt-2 mb-2 mr-2 p-0">
                            <h4 className="d-none d-print-block my-3" style={{color: 'white'}}>.</h4>
                            <h4 className="d-print-none mb-4" style={{fontSize: '2rem'}}>Pathogen Description</h4>
                            {this.props.result && this.props.result.Tests && this.props.result.Tests.length > 0 && this.props.result.Tests.map((t, index) => (
                                <PathogenDescriptions test={t} currentResultValue={currentTestResultValues[index]} currentResultID={this.props.result.TestsAndResultsMap[index].ResultID} allResults={this.props.allResults} />
                            ))}
                        </div>
                    </div>
                    <div className="row" style={{fontSize: '0.8rem'}}>
                        <div className="col-9" style={{borderRight: '1px solid gray'}}>
                            <p className="ml-3">
                                <h5>What is Periodontal Disease?</h5>
                                Periodontal disease is a severe gum infection that damages both soft and hard tissues. If left untreated, it can result in loose teeth or even complete tooth loss. Beyond oral health, periodontal disease has been linked to various systemic conditions and, in some cases, is believed to be a direct cause. 
                            </p>
                            <p className="ml-3">
                                <h5>Why is Early Treatment Crucial?</h5>
                                Addressing periodontal disease promptly and thoroughly can prevent further bone and tooth loss and mitigate complications affecting various organs. The primary indicator of both gingivitis and periodontitis is inflammation. Current research highlights the significant impact of oral inflammation on the development of diseases affecting the heart, lungs, brain, and even unborn babies. Conditions like strokes, heart disease, Alzheimer's, and diabetes have all been associated with gum disease. These conditions are not only severe but can also be financially draining and time-consuming to treat.
                            </p>
                            <p className="ml-3">
                                <h5>How Does OraPath Salivary Diagnostics Benefit My Patients?</h5>
                                <div>
                                    Salivary testing by OraPath offers insights into the specific pathogens contributing to a patient's disease. By interpreting these results and following a structured treatment protocol, including the use of antibiotics when necessary, you can achieve positive outcomes in restoring oral health. The insights gained from these tests can also predict the success of oral surgical procedures like implant placements, grafting, and tooth extractions. By reducing the bacterial or viral load before surgery, the risk of post-operative complications is minimized. The OraPath report details the levels of five oral pathogens associated with periodontal disease. If any of these pathogens exceed a specific limit, a systemic antibiotic may be recommended.
                                </div>
                            </p>
                            <p className="ml-3">
                                <h5>Why is this Information Vital?</h5>
                                <div>
                                Periodontal disease, being a source of inflammation, can be addressed to benefit both oral and systemic health. The OraPath report can guide whether a patient requires antibiotics alongside periodontal therapy or if they have inflammatory markers warranting a physician's attention.
                                </div>
                            </p>
                        </div>
                        <div className="col-3 pl-1">
                            <div className="row">
                                <div className="col-12">
                                    <h5>Oral Risks:</h5>
                                    <ul className="pl-1" style={{listStyleType: 'none'}}>
                                        <li>Gingivitis</li>
                                        <li>Gingival attachment loss</li>
                                        <li>Periodontitis</li>
                                        <li>Severe bone loss</li>
                                        <li>Implant failure</li>
                                        <li>Tooth Loss</li>
                                        <li>Oral Cancer</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5>Systemic Risks:</h5>
                                    <ul className="pl-1" style={{listStyleType: 'none'}}>
                                        <li>Chronic inflammation</li>
                                        <li>Diabetes</li>
                                        <li>IBD</li>
                                        <li>UTI</li>
                                        <li>Respiratory disease</li>
                                        <li>Appendicitis</li>
                                        <li>Arthritis</li>
                                        <li>Cardiovascular disease</li>
                                        <li>Atherosclerosis</li>
                                        <li>Septicemia</li>
                                        <li>Rheumatoid arthritis</li>
                                        <li>Adverse pregnancy outcomes</li>
                                        <li>Alzheimer's disease/dementia</li>
                                        <li>Osteomyelitis</li>
                                        <li>Brain abcesses</li>
                                        <li>Oral Cancer</li>
                                        <li>Colorectal cancer</li>
                                        <li>Aneurysm</li>
                                        <li>Stroke</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-italic my-2 px-3" style={{fontSize: '0.6rem'}}>
                        This real-time quantitative polymerase chain reaction (qPCR) test was developed and performed by IMMYLabs, 133 Ed Noble Pkwy, Norman, OK 73072 CLIA# 37D2236199 and COLA# 32679. The performance characteristics of this laboratory developed test (LDT) were established and determined by IMMYLabs pursuant to Clinical Laboratory Improvement Amendments of 1988 (CLIA) 42 U.S.C. § 263a requirements. Results are not intended to diagnose or treat disease and should not be used as the sole basis for patient management decisions and must be combined with clinical observations and patient history. The U.S. Food and Drug Administration has not approved or cleared this test; The FDA has determined that such clearance or approval is not necessary. Lab Director: Jeff McCormack, PhD, HCLD (ABB).
                    </div>
                </div>
                {/* <div className={"d-none d-print-block pageNumberOne"}>Page 1 of 2</div>
                <div className={"d-none d-print-block pageNumberTwo"}>Page 2 of 2</div> */}
            </div>
        )
    }
}