import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getBoolSelectOptions,
    getBoolSelectVal,
    getNumPages, getPageOptions, hideModal,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";

import Discount from "../../types/Discount";
import {DiscountManagementModal} from "../modals/DiscountManagementModal";
import DiscountAPI from "../../network/DiscountAPI";

const ITEMS_PER_PAGE = 25;

interface DiscountManagementState {
    selectedDiscount?;
    discounts: {label:string,value:number}[]
    name?
    showLoading?
    showInBetween?
    filter?:{
        SelectedDiscount
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    discountQueryResults:Discount[]
}

export class DiscountManagement extends React.Component<any, DiscountManagementState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedDiscount: {} as Discount,
            discounts: [], 
            filter: {
                SelectedDiscount:[],
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            discountQueryResults: [] as any,
        }
        this.createOrModifyDiscount = this.createOrModifyDiscount.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            DiscountAPI.getAllDiscountsForSelect().then(response=> {
                this.setState({ discounts: response.data })
                this.queryDiscounts(1); // calls setState showLoading: false
            })
        });
    }

    queryDiscounts(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await DiscountAPI.filterDiscounts({
                    filter: {
                        SelectedDiscount: this.state.filter.SelectedDiscount
                    }
                });

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Discount data at this time', icon: 'error'})
                }

                let parsedResults = results.data.map(result => {
                    result.DiscountAmount = result.DiscountAmount.toFixed(2)
                    return result;
                })
                
                this.setState({
                    tableData: slicePages(parsedResults, page, ITEMS_PER_PAGE),
                    discountQueryResults: parsedResults,
                    page_options: getPageOptions(getNumPages(parsedResults, ITEMS_PER_PAGE)),
                    showLoading: false,
                });
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        });
    }

    createOrModifyDiscount(discount:Discount){
        this.setState({showLoading:true}, async() => {
            if(discount.ID === null){
                try {
                    let result = await DiscountAPI.newDiscount(discount);
                    if(result.success){
                        sweetalert.fire({ 
                            icon: 'success', 
                            title: '', 
                            text: 'Discount created'
                        }).then(()=>{
                            this.setState({
                                selectedDiscount: {
                                    Name: '',
                                    DiscountCode: '',
                                    DiscountAmount: null,
                                    CreatedBy: "",
                                    CreatedDate: null,
                                    UpdatedBy: "",
                                    UpdatedDate: null,
                                    IsEnabled: null
                                } as Discount 
                            }, () => {
                                DiscountAPI.getAllDiscountsForSelect().then(response => {
                                    this.setState({
                                        discounts: response.data, 
                                        showLoading: false
                                    })
                                })
                                this.queryDiscounts(this.state.selected_page.value);
                            });
                        });
                    }
                    else{
                        sweetalert.fire({
                            icon: 'error', 
                            title: '', 
                            text: result.reason
                        });
                        this.setState({showLoading: false});
                    }
                }catch (e) {
                    console.log(e);
                    this.setState({showLoading: false});
                }
            } else {
                try{
                    let result = await DiscountAPI.editDiscount(discount)
                    if(result.success){
                        sweetalert.fire({
                            icon: 'success', 
                            title: '', 
                            text: 'Discount saved'
                        }).then(()=>{
                            this.setState({
                                selectedDiscount: {
                                    ID: null,
                                    Name: '',
                                    DiscountCode: '',
                                    DiscountAmount: null,
                                    CreatedBy: "",
                                    CreatedDate: null,
                                    UpdatedBy: "",
                                    UpdatedDate: null,
                                    IsEnabled: null
                                } as Discount 
                            }, () => {
                                DiscountAPI.getAllDiscountsForSelect().then(response=> {
                                    this.setState({
                                        discounts: response.data, 
                                        showLoading: false
                                    });
                                });
                                this.queryDiscounts(this.state.selected_page.value);
                            });
                        });
                    }else{
                        sweetalert.fire({
                            icon: 'error', 
                            title: '', 
                            text: result.reason
                        });
                        this.setState({showLoading:false});
                    }
                }
                catch (e) {
                    console.log(e);
                    this.setState({showLoading:false});
                }
            }
        });
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/discount/csv",
                "Discount_Data.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false});
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()
        this.setState({
            tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), 
            direction: this.state.direction === 'asc' ? 'desc' : 'asc'
        });
    }

    changePage(page:number){
        let allDiscounts= this.state.discountQueryResults;
        let returnData = slicePages(allDiscounts, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderDiscountMgmtFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "Discount Name",
                    "key": "SelectedDiscount",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.discounts,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                }, 
                // {
                //     label: "Discount Code",
                //     "key": "DiscountCode",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.discounts.map((d)=>{
                //         // right now discounts is already {label, value}
                //         // need to change the query to pass back the whole obj
                //         // and create the {label, value} in this controller
                //     }),
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    getDiscountDetails(val){
        this.setState({showLoading: true}, async () => {
            try {
                let result = await DiscountAPI.queryDiscounts({ID: val.ID});

                if (result.success) {
                    let discount = result.discount
                    this.setState({
                        selectedDiscount: {
                            ID: discount.ID ? discount.ID : null,
                            Name: discount.Name ? discount.Name : "",
                            DiscountCode: discount.DiscountCode ? discount.DiscountCode : "",
                            DiscountAmount: discount.DiscountAmount ? discount.DiscountAmount.toFixed(2) : null,
                            CreatedBy: discount.CreatedBy ? discount.CreatedBy : "",
                            CreatedDate: discount.CreatedDate ? discount.CreatedDate : null,
                            UpdatedBy: discount.UpdatedBy ? discount.UpdatedBy : null,
                            UpdatedDate: discount.UpdatedDate ? discount.UpdatedDate : null,
                            IsEnabled: discount.IsEnabled !== null ? discount.IsEnabled : null
                        },
                        showLoading: false
                    }, () => showModalNoOutsideClick(DiscountManagementModal.ID))
                }
            } catch (e) {
                console.error(e);
                this.setState({showLoading: false});
            }
        })

    }

    render() {
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <DiscountManagementModal
                discounts={this.state.discountQueryResults}
                selectedDiscount={this.state.selectedDiscount}
                onSubmit={(discount:Discount) => {
                    hideModal(DiscountManagementModal.ID);
                    this.createOrModifyDiscount(discount);
                }}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                    <main id="main-content" tabIndex={-1} aria-label="Discount Management">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h4>Discount Management</h4>
                            </div>
                            <div className="card-body">
                                {this.renderDiscountMgmtFilterFields()}
                            </div>
                            <div className="card-footer">
                                <button className={"btn immySubmitButtonOutline"}
                                        onClick={ () => {
                                            this.setState({
                                                selected_page: {label: 1, value: 1}}, () => {
                                                this.queryDiscounts(this.state.selected_page.value)
                                            })
                                        }}
                                >Search</button>
                                <button className="btn btn-outline-success float-right"
                                        onClick={()=> this.setState({
                                            selectedDiscount: {
                                                ID: null,
                                                Name: "",
                                                DiscountCode: "",
                                                DiscountAmount: 0,
                                                CreatedBy: "",
                                                CreatedDate: null,
                                                UpdatedBy: "",
                                                UpdatedDate:null,
                                                IsEnabled: null
                                            } as Discount 
                                        },()=>{
                                            showModalNoOutsideClick(DiscountManagementModal.ID)
                                        })}
                                >Create New</button>
                            </div>
                        </div>
                        </main>
                    </div>
                    
                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold">
                                    <h4 className="text-center text-md-left">Discount
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.discountQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className=" align-middle float-right pages ">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                aria-label="Table Page Number"
                                                noOptionsMessage={() => "No option"}
                                                value={this.state.selected_page}
                                                onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                    () => this.changePage(e.value))}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div>
                                    </section>
                                    <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button>
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable table_style='tableFixHead' columns={[
                                            {
                                                label: "Name", key: "Name",
                                                rawFormat: (val) => {
                                                    return <a href={"#"} className={'tableNameLinkColor'} onClick={() => {
                                                        this.getDiscountDetails(val)
                                                    }}>{val.Name}</a>
                                                },
                                                popoverText: "Click to sort by Name"
                                            },
                                            {
                                                label: "Discount Code", key: "DiscountCode",
                                                popoverText: "Click to sort by Discount Code"
                                            },
                                            {
                                                label: "Discount Amount", key: "DiscountAmount",
                                                popoverText: "Click to sort by Discount Amount",
                                                rawFormat: (val)=>{
                                                    return <p>${val.DiscountAmount}</p>
                                                }
                                            },
                                            {
                                                label: "Is Enabled", key: "IsEnabled",
                                                popoverText: "Click to sort by Is Enabled",
                                                rawFormat: (val)=>{
                                                    let string = getBoolSelectVal(val.IsEnabled)
                                                    return <p>{string.label}</p>
                                                }
                                            },
                                        ]}
                                        table_data={this.state.tableData}
                                        columnClickedCallback={(col => {
                                            this.useSorter(col);
                                        })} 
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>)
    }
}