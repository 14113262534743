import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getNumPages, 
    getPageOptions, 
    hideModal,
    parseDate,
    showModalNoOutsideClick,
    slicePages,
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import { Transaction } from "../../types/Transaction";
import { TransactionDetailsModal } from "../modals/TransactionDetailsModal";
import PaymentAPI from "../../network/PaymentAPI";
import Facility from "../../types/Facility";
import AdminAPI from "../../network/AdminAPI";

const ITEMS_PER_PAGE = 25;

interface TransactionsState {
    selectedTransaction?;
    transactions: {label:string,value:number}[]
    facilities
    facilityNamesMapped?
    name?
    showLoading?
    showInBetween?
    filter?:{
        SelectedFacility
        TransID
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    // transactionQueryResults:Transaction[]
    transactionQueryResults
}

export class Transactions extends React.Component<any, TransactionsState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedTransaction: {} as Transaction,
            transactions: [], 
            facilityNamesMapped: [],
            facilities:[],
            filter: {
                SelectedFacility:null,
                TransID:null
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            transactionQueryResults: [] as any,
        }

        this.clear = this.clear.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            AdminAPI.getFacilitiesForSubmissionForm().then(data => {
                let facs = data.authorizedFacilities;
                let facilityNamesMapped = this.fromSet(
                  facs
                    .filter((f) => f.FacilityName)
                    .map((obj) => {
                      return { label: obj.FacilityName, value: obj.ID };
                    })
                );

                this.setState({ 
                    facilities: facs,
                    facilityNamesMapped: facilityNamesMapped.sort(), 
                    showLoading:false
                })
            })
        });
    }
    clear() {
        this.setState({
            filter:{
                SelectedFacility:null,
                TransID:null
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            transactionQueryResults: [] as any,
        });
    }
    clearFilterState: () => void = null;

    assignClearState(func) {
        this.clearFilterState = func;
    }

    fromSet(a) {
        return Array.from(new Set(a)).filter((a) => a);
    }

    queryTransactions(page:number) {
        this.setState({showLoading:true}, async () => {
            if(!this.state.filter.SelectedFacility &&
                !this.state.filter.TransID){
                return sweetalert.fire({icon: 'error', text: 'Please select a Filter', title:''})
                    .then((confirm)=>{
                        this.setState({showLoading:false});
                })
            }

            try {
                let results = await PaymentAPI.filterTransactions(
                    { SelectedFacility: this.state.filter.SelectedFacility,
                      TransID: this.state.filter.TransID
                     },
                    page
                );

                if(!results.success){
                    return this.setState({showLoading:false}, ()=>{
                        const reason = results.reason ? results.reason : 'Unable to filter Transaction data at this time';
                        return sweetalert.fire({title: '', text: reason, icon: 'error'})
                    })
                }

                if(results.success && results.data?.totalNumInResultSet === 0){
                    return this.setState({showLoading:false}, ()=>{
                        const reason = results.reason ? results.reason : 'No Transaction data for Facility at this time';
                        return sweetalert.fire({title: '', text: reason, icon: 'info'})
                    })
                }

                let Results = results.data?.transactions?.transaction ? results.data?.transactions?.transaction : [results.data?.transaction]
                this.setState({
                    tableData: slicePages(Results, page, ITEMS_PER_PAGE),
                    // tableData: slicePages(results.data?.transactions?.transaction, page, ITEMS_PER_PAGE),
                    transactionQueryResults: Results,
                    // transactionQueryResults: results.data?.transactions?.transaction,
                    page_options: getPageOptions(getNumPages(Results, ITEMS_PER_PAGE)),
                    // page_options: getPageOptions(getNumPages(results.data?.transactions?.transaction, ITEMS_PER_PAGE)),
                    showLoading: false,
                });
            } catch (e) {
                console.error(e);
                sweetalert.fire({icon:'error',title:'',text:'Error searching transactions. Please try again later.'})
                this.setState({showLoading: false});
            }
        });
    }

    //TODO: implement logic on backend for csv export
    handleExportToCSV(){
        try{
            this.setState({showLoading: true}, async () =>{
                await NetworkUtil.downloadCSV(
                    "/api/admin/payment/csv",
                    "Transaction_Data.xlsx",
                    {filter: this.state.filter}
                )
                this.setState({showLoading: false});
            });
        } catch (e){
            console.error(e);
            sweetalert.fire({icon:'error',title:'',text:'Error exporting data to CSV file. Please try again later.'});
            this.setState({showLoading: false});
        }
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()
        this.setState({
            tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), 
            direction: this.state.direction === 'asc' ? 'desc' : 'asc'
        });
    }

    changePage(page:number){
        let allTransactions = this.state.transactionQueryResults;
        let returnData = slicePages(allTransactions, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderTransactionFilterFields(){
        return (
            <FilterCard 
            passClearStateFunc={this.assignClearState}
            fields={[
                {
                    label: "Facility",
                    "key":"SelectedFacility",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.facilityNamesMapped,
                    isMapped: true,
                    isMulti: false,
                    textType: 'text',
                    placeholder: "Please select...",
                    disable: this.state.filter.TransID ? true : false
                }, 
                {
                    label: "Transaction ID",
                    "key":"TransID",
                    type: FIELD_TYPE.TEXT,
                    textType: 'text',
                    placeholder: "Enter Transaction Number...",
                    disable: this.state.filter.SelectedFacility ? true : false
                }, 
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    getTransactionDetails(val){
        this.setState({showLoading: true}, async () => {
            try {
                
                let result = await PaymentAPI.queryTransaction({TransID: val.transId});

                if (result.success) {
                    let transaction = result.data.transaction
                    this.setState({
                        selectedTransaction: transaction,
                        showLoading: false
                    }, () => showModalNoOutsideClick(TransactionDetailsModal.ID))
                }
            } catch (e) {
                console.error(e);
                this.setState({showLoading: false});
            }
        })

    }

    render() {
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <TransactionDetailsModal
                transactions={this.state.transactionQueryResults}
                selectedTransaction={this.state.selectedTransaction}
                selectedFacility={this.state.facilities.find((f)=>{
                    return f.ID === this.state.filter.SelectedFacility
                })}
                onSubmit={(transaction:Transaction) => {
                    hideModal(TransactionDetailsModal.ID);
                }}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h4>Transaction Report</h4>
                            </div>
                            <div className="card-body">
                                {this.renderTransactionFilterFields()}
                            </div>
                            <div className="card-footer">
                                <button className={"btn immySubmitButtonOutline"}
                                        onClick={ () => {
                                            this.setState({
                                                selected_page: {label: 1, value: 1}}, () => {
                                                this.queryTransactions(this.state.selected_page.value)
                                            })
                                        }}
                                >Search</button>

                                <button
                                    className="btn mr-md-5 float-right verlag-bold immyClearButtonOutline"
                                    onClick={() => {
                                        this.clear();
                                        this.clearFilterState();
                                    }}
                                >Clear Filter</button>
                            </div>
                        </div>
                    </div>
                    
                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold stickToTop">
                                    <h4 className="text-center text-md-left">Transactions
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-right'}>Total: {this.state.transactionQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className=" align-middle float-right pages ">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "No option"}
                                                value={this.state.selected_page}
                                                onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                    () => this.changePage(e.value))}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div>
                                    </section>
{/* TODO Finish logic for Export To CSV functionality then unhide button */}
                                    {/* <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button> */}
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable table_style='tableFixHead' columns={[
                                            {
                                                label: "Invoice Number", key: "invoiceNumber",
                                                rawFormat: (val) => {
                                                    return <a href={"#!"} 
                                                              className={'tableNameLinkColor'} 
                                                              onClick={() => {
                                                                this.getTransactionDetails(val)
                                                            }}>{val.invoiceNumber ? val.invoiceNumber : val.order?.invoiceNumber}</a>
                                                },
                                                popoverText: "Click to sort by Invoice Number"
                                            },
                                            {
                                                label: "Submitted", key: "submitTimeUTC",
                                                rawFormat: (val) => {
                                                    return <p>{parseDate(val.submitTimeUTC)}</p>
                                                },
                                                popoverText: "Click to sort by Submitted Date"
                                            },
                                            {
                                                label: "Amount", key: "settleAmount",
                                                popoverText: "Click to sort by Amount",
                                                rawFormat: (val)=>{
                                                    return <p>${val.settleAmount.toFixed(2)}</p>
                                                }
                                            },
                                        ]}
                                        table_data={this.state.tableData}
                                        columnClickedCallback={(col => {
                                            this.useSorter(col);
                                        })} 
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>)
    }
}